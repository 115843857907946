@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url("../src/fonts/Helvetica.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Helvetica-Bold";
  src: local("Helvetica-Bold"),
    url("../src/fonts/Helvetica-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica-Light";
  src: local("Helvetica-Light"),
    url("../src/fonts/Helvetica-Light.ttf") format("truetype");
  font-weight: light;
}

a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
span,
strong,
input,
select,
option {
  font-family: Helvetica !important;
}

.hide_me {
  display: none !important;
}

.form-switch .form-check-input {
  width: 2.5em;
  height: 1.4em;
}

/*  nav bar css start  */
.navbar_cstm .container {
  max-width: 93%;
}

.navbar_cstm div#responsive-navbar-nav a.nav-link:hover {
  color: white;
}

.navbar_cstm .nav-link {
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

a.nav-link.active {
  border-bottom: 2px solid;
}

.navbar_cstm {
  position: sticky;
  top: 0;
  z-index: 99999999999;
}

.navbar_cstm div#responsive-navbar-nav a.nav-link:hover {
  color: gainsboro;
}

/* navbar css end here */

/* login page css start */

.cstm_login {
  max-width: 45%;
  margin: auto;
  padding: 80px 0px;
}

@media only screen and (max-width:768px) {
  .cstm_login {
    max-width: 100%;
    padding: 50px 20px;
  }
}


/* login page css end  */

/* Homepage start here */
@media (min-width:1400px) and (max-width:1920px) {
  .cstm_home_App {
    padding-top: 80px !important;
  }
}

​ .cstm_home_App {
  flex-direction: row;
  padding-left: 4%;
  padding-right: 4%;
  justify-content: space-between;
  padding-top: 7%;
}

@media only screen and (max-width:1399px) {
  .cstm_home_App {
    padding-left: 0%;
    padding-right: 0%;
  }
}

@media only screen and (max-width:1199px) {
  .cstm_home_App {
    flex-direction: column;

  }
}

.cstm_home_App .left-text-con {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width:1199px) {
  .cstm_home_App .left-text-con {
    align-items: center;
  }
}

.cstm_home_App .left-text {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 66px;
}

span.err_cstm_redi {
  color: red;
  font-size: 14px;
  position: absolute;
}

span.err_cstm_redi1 {
  color: red;
  font-size: 14px;
  position: absolute;
}

.cstm_home_App .left-text1 {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 66px;
  margin-top: -15px;
  line-height: 80px;
}

@media only screen and (max-width:537px) {
  .cstm_home_App .left-text1 {
    text-align: center;
  }
}

.cstm_home_App .left-text-tm {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 20px;
  margin-left: -15px;
}

.cstm_home_App .below-text {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px !important;
  color: #5D5D62;
  margin-top: 12px;
  max-width: 550px;
}

@media only screen and (max-width:1199px) {
  .cstm_home_App .below-text {
    text-align: center
  }
}

.cstm_home_App .line-style {
  height: 1px;
  background-color: #64697B;
  margin-top: 5%;
  margin-bottom: 5%;
}

.cstm_home_App .below-con {
  text-align: center;
}

.cstm_home_App .below-con1 {
  text-align: center;
  margin-top: 1.5%;
}


.cstm_home_App .new-study {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 28px;
  color: #1D1C1D;
}

.cstm_home_App .below-text-short {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 11px;
  margin-top: 12px;
  max-width: 556px;
  margin-top: 15%;
}

.cstm_home_App .btn-con {
  text-align: center;
  margin-top: 1.5%;
}

.cstm_home_App .btn-text-style {
  color: #fff;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 11px;
}

.cstm_home_App .left-study-con {
  display: flex;
}

@media only screen and (max-width:1199px) {
  .cstm_home_App .left-study-con {
    justify-content: center;
  }
}

.cstm_home_App .left-study {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgba(199, 212, 242, 0.2);
  width: 90%;
  margin-top: 25px;
  border-radius: 100px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 18px;
  padding-right: 18px;
}

.left-study.container:hover {
  background: #F0F0F3;
}

@media only screen and (max-width:519px) {
  .cstm_home_App .left-study {
    padding-right: 10px;
  }
}

@media only screen and (max-width:1399px) {
  .cstm_home_App .left-study {
    width: 95%;
  }
}

@media only screen and (max-width:1199px) {
  .cstm_home_App .left-study {
    width: 55%;
  }
}

@media only screen and (max-width:991px) {
  .cstm_home_App .left-study {
    width: 75%;
  }
}

@media only screen and (max-width:767px) {
  .cstm_home_App .left-study {
    width: 100%;
  }
}

.cstm_home_App .study-text {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cstm_home_App .yellow-dot {
  background-color: #FFE70B;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 5px;
}

.cstm_home_App .green-dot {
  background-color: #57F87A;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-left: 10px;
}

.cstm_home_App .complete-response {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 0;
  width: 30%;
  text-align: left;
}

.cstm_home_App .states {
  font-family: Helvetica;
  font-weight: 300;
  font-size: 12;
}

.cstm_home_App .time-style {
  color: #1D1C1D;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 0px;
  width: 20%;
  text-align: left;
}

.home_cstm .btn-con.container {
  margin-bottom: 50px;
}

.cstm_home_App .finished-style {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
  margin-left: 20px;
  margin-bottom: 0px;
}


.cstm_home_App .img-div {
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}

.cstm_home_App .next-img {
  width: 13px;
  height: 13px;
}

/* musa css */
.cstm_home_App {
  padding-top: 8%;
}

.cstm_home_App .img-div button {
  border: unset;
  background: transparent;
  padding-top: 0;
  padding-bottom: 0;
}

.cstm_home_App {
  border-bottom: 1px solid black;
}

.cstm_home_App .left-text-con {
  margin-bottom: 80px;
}

.cstm_home_App .left-study {
  width: 100%;
  max-width: 493px;
}

.study-text-dot-con {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 30%;
  text-align: left;
}

button.left-study-main-btn {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.cstm_home_App .complete-response {
  margin-left: 22px;
}

.cstm_home_App .time-style {
  margin-left: 25px;
}

.cstm_home_App .finished-style {
  margin-left: 41px;
}

.below-con.container {
  text-align: center;
  margin-top: 68px;
}

.below-con.container p {
  font-size: 28px;
  line-height: 38px;
  font-family: Helvetica;
  font-weight: 700;
}

.below-text-short {
  text-align: center;
  color: #5D5D62;
}

.btn-con.container {
  text-align: center;
}

.below-con.container {
  text-align: center;
  margin-top: 68px;
  font-weight: 700;
}

.below-con1.container p {
  font-size: 16px;
  font-family: Helvetica;
}

.btn-con.container button.btn-primary {
  width: 146px;
  height: 40px;
  background: #283ED4;
  border: none;
}

.btn-con.container button.btn-primary:hover {
  background: #0d6efd;
}

.btn-con.container button.btn-primary p {
  font-size: 12px;
  margin-bottom: 0px;
  font-family: Helvetica;
}

@media only screen and (max-width:1440px) {
  .cstm_home_App .left-text-con {
    width: 100%;
    max-width: 556px;
    padding-right: 40px;
  }
}

@media only screen and (max-width:1199px) {
  .cstm_home_App .left-text-con {
    max-width: 100%;
    margin-bottom: 20px;
    padding-right: 12px;
  }

  .cstm_home_App .left-study {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width:575px) {
  .cstm_home_App .left-text {
    font-size: 45px;
  }

  .cstm_home_App .left-text1 {
    font-size: 45px;
  }

  .below-con.container {
    margin-top: 30px;
  }

  .below-con.container p {
    font-size: 25px;
  }

  .below-con1.container p {
    font-size: 12px;
  }

  .btn-con.container button.btn-primary p {
    font-size: 12px;
  }

  .cstm_home_App .below-text {
    font-size: 12px !important;
  }
}

@media only screen and (max-width:575px) {
  .cstm_home_App .study-text {
    font-size: 12px;
  }

  .cstm_home_App .yellow-dot {
    margin-left: 5px;
  }

  .cstm_home_App .green-dot {
    margin-left: 5px;
  }

  .cstm_home_App .complete-response {
    margin-left: 15px;
  }

  .cstm_home_App .time-style {
    margin-left: 20px;
  }

  .cstm_home_App .finished-style {
    margin-left: 20px;
  }
}

@media only screen and (max-width:420px) {
  .cstm_home_App .left-text {
    font-size: 35px;
  }

  .cstm_home_App .left-text1 {
    font-size: 35px;
    margin-bottom: 0px;
  }

  .cstm_home_App .study-text {
    font-size: 10px;
  }

  .cstm_home_App .complete-response {
    font-size: 10px;
  }

  .cstm_home_App .time-style {
    font-size: 11px;
  }

  .cstm_home_App .finished-style {
    font-size: 11px;
  }

  .cstm_home_App .complete-response {
    margin-left: 10px;
  }

  .cstm_home_App .time-style {
    margin-left: 12px;
  }

  .cstm_home_App .finished-style {
    margin-left: 12px;
  }
}

/* musa css end */


/* Homepage end here  */

/* study page nav start here */

.cstm_study_nav {
  padding-top: 3.9%;
  padding-bottom: 2.1%;
}

.cstm_study_nav .questionNumbers {
  display: flex;
  align-items: center;
}

.cstm_study_nav .questionNumberIcon {
  display: flex;
  border-radius: 50%;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
}

.cstm_study_nav .questionNumberLine {
  flex: 1 0 auto;
  height: 0;
  border-top: 1px solid #E8E9F3;
  margin-bottom: 20px;
}

.cstm_study_nav .circle-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.cstm_study_nav .circle-con {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #E8E9F3;
}

.cstm_study_nav .circle-con.active {
  background-color: #4273F0;
}

.cstm_study_nav .circle-text {
  color: #ffffff;
  margin: auto;
  font-family: Helvetica;
  font-size: 21px;
  font-weight: 700;
}

.cstm_study_nav .circle-bottom-text {
  color: #1D1C1D;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  top: 12.5%;
  margin-bottom: 0;
}

.studies_cstm_main .cstm_stdy_next.btn.btn-primary {
  background: #283ED4;
}

.studies_cstm_main .cstm_stdy_next.btn.btn-primary[disabled] {
  background: #E1E1E6;
  border-color: #E1E1E6;
}

/* musa css */
.media_study_cstm_img.active label {
  border: 1px solid #008000a6;
}

.studies_cstm_main .study_v1_title {
  width: 100% !important;
  max-width: 583px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 67px;
}

.studies_cstm_main .study_v1_tlt {
  font-size: 35px;
  font-weight: 700;
  font-family: Helvetica;
}

.studies_cstm_main .study_v1_desc {
  font-size: 16px;
  font-family: Helvetica;
  color: #5D5D62;
}

.studies_cstm_main .mb-3.study_pt.row label {
  font-size: 14px;
  font-family: Helvetica;
}

.studies_cstm_main .mb-3.study_client.row label {
  font-size: 14px;
  font-family: Helvetica;
}

.studies_cstm_main .mb-3.study_post_survay.row label {
  font-size: 14px;
  font-family: Helvetica;
}

.studies_cstm_main .mb-3.study_red_link.row label {
  font-size: 14px;
  font-family: Helvetica;
}

.studies_cstm_main .nav.nav-tabs {
  display: none;
}

.studies_cstm_main .study_v1_form {
  margin-top: 80px;
  margin-bottom: 75px;
}

.studies_cstm_main .mb-3.study_pt.row {
  width: 70%;
  height: 43px;
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid #E8E9F3;
  padding-left: 0px;
}

.studies_cstm_main .form-label.col-form-label.col-sm-2 {
  width: 42%;
  margin-top: 10px;
  padding-left: 0;
}

.studies_cstm_main .col-sm-10 {
  margin-top: 10px;
  width: 58%;
}

.studies_cstm_main .form-control {
  width: 100%;
  height: 35px;
  font-size: 14px;
}

.studies_cstm_main .mb-3.study_client.row {
  width: 70%;
  height: 43px;
  display: flex;
  flex-wrap: nowrap;
  border-top: 1px solid #E8E9F3;
  padding-left: 0px;
}

.studies_cstm_main .mb-3.study_post_survay.row {
  width: 70%;
  height: 43px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-top: 1px solid #E8E9F3;
  padding-left: 0px;
}

.studies_cstm_main .mb-3.study_red_link.row {
  width: 70%;
  height: 43px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-top: 1px solid #E8E9F3;
  padding-left: 0px;
}

.studies_cstm_main .mt-3.hstack.gap-3 {
  margin-top: 56px !important;
  margin-bottom: 40px;
}

.studies_cstm_main .cstm_stdy_tb.cstm_stdy_prev.start_active.btn.btn-primary {
  width: 146px;
  height: 40px;
  background: transparent;
  color: black;
  border: 1px solid black;
  font-size: 12px;
  font-family: Helvetica;
}

.studies_cstm_main .success.ms-auto.cstm_stdy_tb.cstm_stdy_next.btn.btn-primary {
  width: 146px;
  height: 40px;
  font-size: 12px;
  font-family: Helvetica;
  border: none;
}

.studies_cstm_main .success.ms-auto.cstm_stdy_tb.cstm_stdy_next.btn.btn-primary:hover {
  background: #0d6efd;
}

.form-check-input:checked {
  background-color: #283ED4;
}

.study_v1_innr .row {
  padding: 0px 10px;
}

@media (min-width:1400px) and (max-width:1920px) {
  .cstm_study_nav {
    padding-top: 52px;
    padding-bottom: 2.1%;
  }
}

@media only screen and (max-width: 1440px) {
  .cstm_study_nav.container {
    max-width: 970px !important;
  }

  .studies_cstm_main {
    width: 100%;
    max-width: 1080px;
    margin: auto;
  }

  .studies_cstm_main .mb-3.study_pt.row {
    width: 80%;
  }

  .studies_cstm_main .mb-3.study_client.row {
    width: 80%;
  }

  .studies_cstm_main .mb-3.study_post_survay.row {
    width: 80%;
  }

  .studies_cstm_main .mb-3.study_red_link.row {
    width: 80%;
  }

  .studies_cstm_main .study_v1_tlt {
    font-size: 35px;
  }

  .studies_cstm_main .study_v1_title {
    font-size: 12px;
  }

  .studies_cstm_main .form-label.col-form-label.col-sm-2 {
    font-size: 14px;
  }

  .studies_cstm_main .form-control {
    width: 100%;
    max-width: unset;
  }
}

/* @media only screen and (max-width: 1366px) {
.study_main_content_cstm.screener_screenerQuestion.container .mt-3.hstack.gap-3{
  margin-top: 70px !important;
}
} */
@media only screen and (max-width: 1200px) {
  .cstm_study_nav {
    padding-left: 70px;
    padding-right: 70px;
  }

  .study_main_content_cstm.container {
    padding: 0px 37px;
  }

  .study_main_content_cstm.container .row {
    margin-left: 0;
  }

  .study_v1_form_left.col {
    padding-left: 0;
  }

  .study_v1_innr .row {
    padding: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .main_cstm.pg_create-study.container {
    width: 100%;
    max-width: 100%;
  }

  .studies_cstm_main .col-sm-10 {
    width: 58%;
  }

  .studies_cstm_main .form-control {
    width: 100%;
    max-width: 100%;
  }

  .pg_studies {
    width: 100%;
    max-width: unset;
  }

  .cstm_study_nav.container {
    max-width: unset;
  }

  .study_main_content_cstm.container {
    max-width: unset;
  }

  .studies_cstm_main .mb-3.study_pt.row {
    width: 100%;
  }

  .studies_cstm_main .mb-3.study_client.row {
    width: 100%;
  }

  .studies_cstm_main .mb-3.study_post_survay.row {
    width: 100%;
  }

  .studies_cstm_main .mb-3.study_red_link.row {
    width: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .cstm_study_nav .circle-con {
    width: 35px;
    height: 35px;
  }

  .cstm_study_nav .circle-text {
    font-size: 12px;
  }

  .cstm_study_nav .circle-bottom-text {
    font-size: 12px;
  }

  .cstm_study_nav {
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 10px;
  }

  .study_main_content_cstm.container {
    padding: 0px 24px;
  }

  .study_v1_innr .row {
    flex-direction: column;
    justify-content: center;
  }

  .studies_cstm_main .col-sm-10 {
    width: 70%;
  }

  .studies_cstm_main .mb-3.study_pt.row {
    width: 100%;
    flex-wrap: wrap;
  }

  .studies_cstm_main .form-label.col-form-label.col-sm-2 {
    width: 30%;
  }

  .studies_cstm_main .form-control {
    width: 100%;
  }

  .studies_cstm_main .mb-3.study_client.row {
    width: 100%;
    flex-wrap: wrap;
  }

  .studies_cstm_main .mb-3.study_post_survay.row {
    width: 100%;
    flex-wrap: wrap;
  }

  .studies_cstm_main .mb-3.study_red_link.row {
    width: 100%;
    flex-wrap: wrap;
  }

  .studies_cstm_main .study_v1_desc {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  .cstm_study_nav .circle-con {
    width: 30px;
    height: 30px;
  }

  .cstm_study_nav .circle-bottom-text {
    font-size: 10px;
  }

  .cstm_study_nav .circle-text {
    font-size: 10px;
  }

  .studies_cstm_main .study_v1_tlt {
    font-size: 26px;
  }

  .studies_cstm_main .form-label.col-form-label.col-sm-2 {
    width: 40%;
  }

  .studies_cstm_main .col-sm-10 {
    width: 60%;
  }

  .studies_cstm_main .form-control {
    width: 100%;
  }
}

.cstm_sudy_alrt {
  margin-bottom: 0;
  position: relative;
  margin-top: 10px;
  margin-bottom: 50px;
}

/* study page nav end here */

/* musa study V2 css start here */
.study_v2_title {
  margin-top: 67px;
  /* padding-left: 60px; */
}

.study_main_content_cstm.defineStudy_typeOfStudy.container .mt-3.hstack.gap-3 {
  /* margin-left: 3.5%;
  margin-right: 3.5%; */
}

.study_v2_type {
  margin-top: 70px;
}

.study_v2_tlt {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: Helvetica;
}

.study_v2_title p {
  font-size: 16px;
  font-family: Helvetica;
}

.stdy_stndrd_v2_img {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  height: 10px;
}

.type_study_lst_cstm {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.stndrd_v2_img_det {
  padding: 10px 15px;
  width: 25%;
}

.stnd_v2_title {
  font-size: 20px;
  font-weight: 700;
  font-family: Helvetica;
  padding-left: 15px;
}

.study_v2_desc_stndrd {
  margin-top: 30px;
}

.study_v2_desc {
  color: #5D5D62;
}

.cstm_stdy_tb.cstm_stdy_prevsuccess.next_active.btn.btn-primary {
  width: 100%;
  max-width: 146px;
  height: 40px;
  background: transparent;
  color: #5D5D62;
  border: 1px solid #5D5D62;
  font-size: 12px;
  font-family: Helvetica;
}

.stndrd_v2_img_det label.active {
  box-shadow: 0px 0px 10px grey;
  border-radius: 20px;
  width: 100%;
}

@media only screen and (max-width: 1440px) {
  .studies_cstm_main {
    max-width: 1160px;
  }

  .study_v2_tlt {
    font-size: 35px;
  }

  .study_v2_desc {
    font-size: 12px;
  }

  .study_v2_desc_stndrd {
    font-size: 12px;
  }

  .stnd_v2_title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  .main_cstm.pg_create-study.container {
    max-width: 95%;
  }

  .study_v2_title {
    padding-left: 0px !important;
  }

  .stndrd_v2_img_det {
    width: 25%;
  }

  .stndrd_v2_img_det {
    padding-left: 0;
  }

  .study_main_content_cstm.defineStudy_typeOfStudy.container .mt-3.hstack.gap-3 {
    margin-left: 1%;
    margin-right: 1%;
  }
}

@media only screen and (max-width: 991px) {
  .study_main_content_cstm.container {
    max-width: 95%;
  }
}

@media only screen and (max-width: 850px) {
  .study_v2_title {
    padding: 0px 10px;
  }

  .type_study_lst_cstm {
    justify-content: start;
    flex-wrap: wrap;
  }

  .stndrd_v2_img_det {
    width: 33%;
    padding-left: 0;
    padding-bottom: 15px;
  }

  .studies_cstm_main .mt-3.hstack.gap-3 {
    margin-top: 70px !important;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 700px) {
  .stndrd_v2_img_det {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .study_main_content_cstm.container .col {
    padding: 0px;
  }

  .stndrd_v2_img_det {
    width: 50%;
  }

  .study_main_content_cstm.container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  .stndrd_v2_img_det {
    width: 100%;
  }

  .stdy_stndrd_v2_img {
    max-width: 100%;
    height: 10px;
  }

  .stndrd_v2_img_det label {
    width: 100%;
  }

  .study_v2_desc_stndrd {
    margin-top: 15px;
    max-width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .stndrd_v2_img_det {
    width: 100%;
  }

  .cstm_stdy_tb.cstm_stdy_prevsuccess.next_active.btn.btn-primary {
    width: 100%;
    max-width: 50%;
  }

  .studies_cstm_main .success.ms-auto.cstm_stdy_tb.cstm_stdy_next.btn.btn-primary {
    width: 100%;
    max-width: 50%;
    height: 40px;
  }
}

.cstm_study_nav.container {
  max-width: 1150px;
}

.main_cstm.pg_create-study.container {
  max-width: 1440px;
}

.study_main_content_cstm.container {
  max-width: 100%;
}

.study_v2_title {
  /* padding-left: 50px; */
}

.type_study_lst_cstm {
  justify-content: center;
}

.stndrd_v2_img_det label {
  padding: 20px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0px 0px 10px lightgray;
  border-radius: 20px;
}

.study_v2_desc_stndrd {
  margin-top: 30px;
  width: 100%;
  max-width: 85%;
  font-size: 14px;
  font-family: Helvetica;
  color: #090909;
  padding-left: 15px;
}

/* study V2 css End here */

/* musa number styling start */
.cstm_study_nav .circle-div {
  position: relative;
}

.cstm_study_nav .circle-bottom-text {
  position: absolute;
  top: 100%;
  width: 135px;
  display: block;
  text-align: center;
  margin-top: 10px;
}

.cstm_study_nav .questionNumberLine {
  margin: 0px 8px;
}

@media only screen and (max-width: 350px) {
  .cstm_study_nav .circle-bottom-text {
    width: unset;
  }
}

/* number styling end */

/* media study page css */
.study_cstm_media_inner {
  margin-top: 67px;
}

.stdy_media_lr_cstm_p2.hstack.gap-3.minBreakpoint-xxs {
  display: flex;
  justify-content: space-between;
  margin-top: 65px;
  align-items: flex-start;
}

.stdy_mdia_lft_p2_innr {
  width: 50%;
}

.stdy_mdia_rft_p2_innr {
  width: 50%;
}

.cstm_witch_std_mdia {
  width: 25%;
}

.media_cstm_imges_study {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media_study_cstm_img label {
  border: 1px solid lightgrey;
  border-style: dotted;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
  width: 142px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.media_study_cstm_img label img {
  width: 39px;
  height: 43px;
}

.form-switch .form-check-input {
  margin-right: 10px;
}

.stdy_mdia_rgt_p2_innr {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin-left: 25px;
}

.stdy_mdia_rgt_p2_innr:nth-child(2) {
  border-left: 2px solid grey;
  margin-left: 0px;
  border-color: #E8E9F3;
}

.stdy_mdia_rgt_p2_innr:nth-child(2) .hstack.gap-3 button.add_screener_bx_option {
  padding: 0px 10px;
}

.stdy_mdia_lft_p2_innr:nth-child(1) {
  margin-right: 13px;
}

.cstm_media_rgt .row .col {
  padding: 0px 10px;
}

.studies_cstm_main .form-control {
  height: 40px;
  font-family: Helvetica;
}

.stdy_mdia_rgt_p2_innr .hstack {
  width: 100%;
  margin-left: 25px;
}

.media_cstm_attr_study {
  width: 75%;

}

.stdy_md_img p {
  margin-bottom: 5px;
  text-align: center;
  font-size: 14px;
  font-family: 'Helvetica';
}

.media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row {
  margin-bottom: 0px !important;
  padding: 0px 10px;
  align-items: flex-start;
  height: 150px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
}

.media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row::-webkit-scrollbar {
  width: 15px;
}

.media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row::-webkit-scrollbar-thumb {
  background: #fff;
  border: 1px solid grey;
  border-radius: 10px;
}

.media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
  margin-top: 20px;
}

.media_wrd_attr_rght {
  width: 80%;
}

.media_wrd_attr_rght .row .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 33.33%;
  max-width: 33.33%;
  padding: 0;
  margin: 20px 12px;
  position: relative;
}

.media_wrd_attr_rght .media_study_cstm_img label {
  padding: 35px;
}

.media_study_cstm_img label img {
  width: 30px;
  height: 30px;
}

.media_cstm_attr_study.active .media_wrd_attr_rght {
  height: 200px;
  overflow-x: hidden;
}

.media_cstm_attr_study.active .media_wrd_attr_rght::-webkit-scrollbar {
  width: 15px;
}

.media_cstm_attr_study.active .media_wrd_attr_rght::-webkit-scrollbar-thumb {
  background: #fff;
  border: 1px solid grey;
  border-radius: 10px;
}

.media_cstm_attr_study.active .media_wrd_attr_rght::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
  margin-top: 20px;
}

.media_cstm_attr_study.active .cstm_media_rgt {
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.media_cstm_attr_study.active .cstm_media_rgt::-webkit-scrollbar {
  width: 15px;
}

.media_cstm_attr_study.active .cstm_media_rgt::-webkit-scrollbar-thumb {
  background: #fff;
  border: 1px solid grey;
  border-radius: 10px;
}

.media_cstm_attr_study.active .cstm_media_rgt::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
  margin-top: 20px;
}

.media_wrd_attr_rght {
  width: 100%;
  max-width: 440px;
  padding: 0px 10px;
}

p.media_attr_title {
  font-weight: bold;
  font-size: 14px;
  font-family: Helvetica;
}

.stdy_mdia_rgt_p2_innr:nth-child(2) p.media_attr_title {
  padding: 0px 10px;
}

.arr_img_media img.std_med_img {
  width: 90px;
  height: 90px;
  max-height: 100%;
  object-fit: contain;
}

img.cross_sign_std.img-thumbnail {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.main_screener_v2 .screener_bx_option.multiple_choices .radioCon1 input:focus-visible {
  border: none;
  outline: none;
}

.cross_img_std_med {
  position: relative;
}

.stdy_md_img {
  position: relative;
}

.arr_img_media_brnd img {
  width: 100%;
}

.arr_img_media_brnd img {
  width: 130px;
  height: 130px;
  max-height: 100%;
  object-fit: contain;
}

.study_cstm_media_inner h1.study_media_titl_p2 {
  font-size: 35px;
  font-weight: 700;
  font-family: Helvetica;
  color: #090909;
}

.study_cstm_media_inner p.stdy_media_detail_p2 {
  font-size: 16px;
  font-family: Helvetica;
  color: #64697B;
}

.study_cstm_media_inner .cstm_witch_std_mdia label {
  font-size: 14px;
  font-family: Helvetica;
}

.stdy_mdia_lft_p2_innr button.add_screener_bx_option {
  width: 100%;
  max-width: 142px;
  height: 40px;
  font-size: 12px;
  border: none;
  background: #283182;
  color: white;
  border-radius: 5px;
}

.hstack.gap-3.minBreakpoint-xxs button.add_screener_bx_option {
  width: 100%;
  max-width: 250px;
  height: 40px;
  font-size: 14px;
  border: none;
  background: transparent;
  color: #283182;
  border-radius: 5px;
  text-align: left;
  padding: 0px;
}

.media_cstm_imges_study .add_more_btn_media {
  margin-top: 20px;
  padding: 0px 0px;
}

.mb-3.cstm_media_brnd_words.row {
  margin-bottom: 0px;
}

.mb-3.cstm_media_brnd_words.row .brnd_txt_wrpr {
  width: 49%;
  margin-bottom: 10px;
  position: relative;
  /* padding: 0px 10px; */
}

.media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row .brnd_txt_wrpr {
  padding: 0px;
  padding-right: 20px;
}

.mediaAtt_wrpr {
  width: 50%;
  margin-bottom: 10px;
  position: relative;
}

.mediaAtt_wrpr:nth-child(1),
.mediaAtt_wrpr:nth-child(2) {
  margin-top: 5px;
}

.brnd_txt_wrpr .cross_img_std_med {
  top: -2px;
  float: right;
  right: -10px;
}

.mediaAtt_wrpr .cross_img_std_med {
  top: -2px;
  float: right;
}

.media_cstm_attr_study button.add_screener_bx_option {
  margin-top: 0px !important;
}

.stdy_mdia_lft_p2_innr .media_cstm_imges_study .hstack.gap-3 {
  flex-wrap: wrap;
}

.stdy_mdia_lft_p2_innr .media_cstm_imges_study.active .cstm_media_gpt.hstack.gap-3 {
  height: 250px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.stdy_mdia_lft_p2_innr .media_cstm_imges_study.active .cstm_media_gpt.hstack.gap-3::-webkit-scrollbar {
  width: 15px;
}

.stdy_mdia_lft_p2_innr .media_cstm_imges_study.active .cstm_media_gpt.hstack.gap-3::-webkit-scrollbar-thumb {
  background: #fff;
  border: 1px solid grey;
  border-radius: 10px;
}

.stdy_mdia_lft_p2_innr .media_cstm_imges_study.active .cstm_media_gpt.hstack.gap-3::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
  margin-top: 20px;
}

.arr_img_media_brnd .cross_img_std_med {
  top: -4px;
}

.arr_img_media_brnd {
  margin-top: 25px;
  margin-left: 11px;
}

.media_cstm_imges_study.screnrMedia .arr_img_media_brnd {
  margin-top: 0px;
  margin-left: 11px;
}

.arr_img_media .cross_img_std_med {
  top: -23px;
}

.arr_img_media {
  margin-top: 5px;
  margin-left: 5px;
}

/* .cstm_media_rgt .mb-3.row {
  height: 240px;
  overflow-x: hidden;
  overflow-y: scroll;
} */
.stdy_md_img .attr_wrp .cross_img_std_med {
  top: -2px;
  float: right;
  right: -10px;
}

.media_cstm_attr_study .cstm_media_rgt .mb-3.row {
  overflow-y: hidden;
}

.media_cstm_attr_study .cstm_media_rgt {
  height: 150px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.add_more_btn_media {
  margin-top: 20px;
  padding: 0px 0px;
}

.cstm_std_img_wrpr .cross_img_std_med {
  float: right;
  top: -2px;
  right: -10px;
}

@media only screen and (max-width: 1440px) {
  .media_study_cstm_img label {
    padding: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .media_wrd_attr_rght .media_study_cstm_img label {
    padding: 25px;
    width: 100%;
  }

  .media_wrd_attr_rght .media_study_cstm_img label img {
    width: 28px;
    height: 28px;
  }

  .stdy_md_img p {
    font-size: 11px;
    font-family: Helvetica;
  }

  .media_wrd_attr_rght {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }

  .mb-3.cstm_media_brnd_words.row .brnd_txt_wrpr {
    padding-left: 0;
  }

  .mediaAtt_wrpr {
    padding-left: 0px;
  }

  .hstack.gap-3.minBreakpoint-xxs button.add_screener_bx_option {
    padding: 0;
  }

  .media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row {
    padding: 0px;
    padding-right: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .media_study_cstm_img label {
    padding: 30px;
  }

  .cstm_witch_std_mdia {
    width: 30%;
  }

  .studies_cstm_main .form-control {
    height: 35px;
  }

  .mb-3.cstm_media_brnd_words.row .brnd_txt_wrpr {
    width: 92%;
  }

  .mediaAtt_wrpr {
    width: 92%;
  }

  .mb-3.cstm_media_brnd_words.row {
    margin-top: 5px;
  }

  .stdy_mdia_rgt_p2_innr:nth-child(2) {
    border-left: none;
  }

  .stdy_mdia_rgt_p2_innr .hstack {
    margin-left: 0px;
  }

  .media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row .brnd_txt_wrpr {
    padding-right: 0px;
  }
}

@media only screen and (max-width: 890px) {
  .media_study_cstm_img label {
    padding: 50px;
  }

  .stdy_media_lr_cstm_p2.hstack.gap-3.minBreakpoint-xxs {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .stdy_mdia_lft_p2_innr {
    width: 100%;
  }

  .stdy_mdia_rgt_p2_innr {
    width: 100%;
    margin-left: 0px;
  }

  .media_study_cstm_img label img {
    width: 28px;
    height: 28px;
  }

  .media_wrd_attr_rght .row .col {
    margin: 10px;
  }

  .media_wrd_attr_rght .media_study_cstm_img label {
    padding: 35px;
  }

  .stdy_mdia_lft_p2_innr .media_cstm_imges_study .hstack.gap-3 {
    padding: 0px 10px;
  }

  .mb-3.cstm_media_brnd_words.row .brnd_txt_wrpr {
    padding: 0px 0px;
  }

  .cstm_media_rgt .row .col {
    padding: 0px 0px;
  }

  .mediaAtt_wrpr {
    padding-left: 0px;
  }

  .stdy_mdia_rgt_p2_innr:nth-child(2) p.media_attr_title {
    padding: 0;
  }

  .stdy_mdia_rgt_p2_innr:nth-child(2) .hstack.gap-3 button.add_screener_bx_option {
    padding: 0px 0px;
  }
}

@media only screen and (max-width: 575px) {
  .mb-3.cstm_media_brnd_words.row .col {
    margin: 0px 15px 0px 0px;
  }

  .media_cstm_imges_study.active .mb-3.cstm_media_brnd_words.row {
    padding: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 470px) {
  .cstm_media_rgt .mb-3.row {
    flex-direction: column;
    margin-bottom: 0px !important;
  }

  .cstm_media_rgt .row .col {
    margin: 10px 0px;
  }

  .media_study_cstm_img label img {
    width: 23px;
    height: 23px;
  }

  .form-switch .form-check-input {
    width: 30px;
    height: 16px;
  }

  label.form-check-label {
    font-size: 13px;
  }

  .mb-3.cstm_media_brnd_words.row {
    display: flex;
    /* flex-direction: column; */
  }

  .mb-3.cstm_media_brnd_words.row .col {
    margin: 5px 5px 5px 0px;
  }

  .media_wrd_attr_rght .row .col {
    align-items: flex-start;
    width: 50%;
    max-width: 50%;
  }

  .media_wrd_attr_rght .media_study_cstm_img label img {
    width: 23px;
    height: 23px;
  }

  .arr_img_media_brnd img {
    width: 100px;
    height: 100px;
  }

  .arr_img_media img.std_med_img {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 400px) {
  .studies_cstm_main .mt-3.hstack.gap-3 {
    width: 100%;
  }

  .ms-auto.cstm_rgt_std_btn {
    width: 100%;
  }

  .study_main_content_cstm.addMedia.container .cstm_stdy_tb.cstm_stdy_prevsuccess.next_active.btn.btn-primary {
    max-width: 100%;
    height: 40px;
    font-size: 12px;
    padding: 0;
  }

  .studies_cstm_main .success.ms-auto.cstm_stdy_tb.cstm_stdy_next.btn.btn-primary {
    max-width: 100%;
  }
}

@media only screen and (max-width: 390px) {
  label.form-check-label {
    font-size: 12px;
  }

  .media_wrd_attr_rght .row .col {
    margin: 10px 5px;
  }

  .arr_img_media_brnd img {
    width: 80px;
    height: 80px;
  }

  .arr_img_media img.std_med_img {
    width: 60px;
    height: 60px;
  }
}

/* media study page css end */

/* create screener css start here */

.topTabCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.topLineCon {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 273px;
}

.topLine.active {
  background-color: #4273F0;
}

.topLine {
  background-color: rgb(232, 233, 243);

  height: 5px;
  width: 100%;
  max-width: 270px;
}

.topText {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  margin-top: .5em;
  color: rgb(163, 170, 194);
}

.topLine.active+p.topText {
  color: black !important;
}

@media only screen and (max-width: 1000px) {
  .topLineCon {
    width: 33.33%;
  }
}

@media only screen and (max-width: 767px) {
  .topText {
    font-size: 14px;
  }
}

@media only screen and (max-width: 550px) {
  .topText {
    font-size: 12px;
  }
}

@media only screen and (max-width: 475px) {
  .topLineCon {
    margin-left: 5px !important;
  }

  .topText {
    font-size: 11px;
  }
}

@media only screen and (max-width: 410px) {
  .topText {
    font-size: 10px;
  }
}

/* .study_cstm_screener .wrapper{
  padding-left: 4%;
  padding-right: 3%;
} */

.study_cstm_screener .stydyServey {
  color: #1D1C1D;
  font-size: 35px;
  font-family: Helvetica;
  font-weight: 700;
  margin-top: 70px;
  font-weight: 700;
}

.study_cstm_screener .loremIpsum {
  color: #64697B;
  font-size: 16px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 21px;
  margin-top: -1%;
}

.study_cstm_screener .buttonCon {
  display: flex;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 7%;
}

.study_cstm_screener .buttonCon .button {
  background-color: #283182;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 202px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  margin: auto;
}

@media (min-width:1400px) and (max-width:1920px) {
  .study_cstm_screener .buttonCon {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 1440px) {
  .study_cstm_screener .buttonCon .button {
    margin: unset;
    margin-right: 15px;
  }
}

/* create screener end here */


/* taking screeners css start */

.main_screener_v1 .stydyServey {
  color: #1D1C1D;
  font-size: 35px;
  font-family: Helvetica;
  font-weight: 700;
  margin-top: 8%;
}

.main_screener_v1 .loremIpsum {
  color: #64697B;
  font-size: 16px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 21px;
  margin-top: -1%;
}

.main_screener_v1 .titleBox {
  display: flex;
  border: 1px solid #A3AAC2;
  width: 100%;
  height: 77px;
  margin-top: 3.1%;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10.5%;
}

.main_screener_v1 .titleInput {
  height: 43px;
  width: 382px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background-color: #F0F0F3;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  margin-left: 1.7%;
  padding-left: 1%;
  color: "#000000";
  font-family: Helvetica;
}

.main_screener_v1 .BottombuttonCon {
  display: flex;
  justify-content: space-between;
  margin-top: 16.5%;
  padding-left: 3.8%;
  padding-right: 3.8%;
}

.main_screener_v1 .Exitbutton {
  background-color: #ffffff;
  /* border: none; */
  border-width: 1px;
  border-color: #1D1C1D;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.main_screener_v1 .Nextbutton {
  background-color: #E8E9F3;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

@media (min-width:1400px) and (max-width:1920px) {
  .main_screener_v1 .stydyServey {
    margin-top: 120px !important;
  }

  .main_screener_v1 .titleBox {
    margin-bottom: 145px;
  }
}

@media only screen and (max-width: 768px) {
  .main_screener_v1 .stydyServey {
    font-size: 30px;
    margin-top: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .main_screener_v1 .stydyServey {
    font-size: 25px;
    margin-bottom: 5px;
  }
}

/* taking screeners css end here */

/* screenerV3 css start */


.topTabCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.topLineCon {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 273px;
}

.topLine.active {
  background-color: #4273F0;
}

.topLine {
  background-color: rgb(232, 233, 243);

  height: 5px;
  width: 100%;
  max-width: 270px;
}

.topText {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  margin-top: .5em;
  color: rgb(163, 170, 194);
}

.main_screener_v3 .wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  max-width: 975px;
  margin: auto;
}

.main_screener_v3 .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_screener_v3 .wrapper.screner_scroll_active {
  overflow-y: scroll;
  height: 50vh;
}

.main_screener_v3 .stydyServey {
  color: #1D1C1D;
  font-size: 35px;
  font-family: Helvetica;
  font-weight: 700;
  margin-top: 14.5%;
}

.main_screener_v3 .loremIpsum {
  color: #64697B;
  font-size: 12px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 21px;
  margin-top: -1%;
}

.main_screener_v3 .titleBox {
  display: flex;
  border: 1px solid #A3AAC2;
  width: 804px;
  height: 77px;
  margin-top: 1%;
  border-radius: 5px;
  align-items: center;
}

.main_screener_v3 .titleInput {
  height: 43px;
  width: 382px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background-color: #E8E9F34D;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  margin-left: 1.7%;
  padding-left: 1%;
  color: "#000000"
}

.main_screener_v3 .BottombuttonCon {
  display: flex;
  justify-content: space-between;
  margin-top: 16.5%;
  padding-left: 3.8%;
  padding-right: 3.8%;
}

.main_screener_v3 .Exitbutton {
  background-color: #ffffff;
  /* border: none; */
  border-width: 1px;
  border-color: #1D1C1D;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.main_screener_v3 .Nextbutton {
  background-color: #E8E9F3;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}


.main_screener_v3 .dropdownCon {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 6%;
  /* align-items: center; */
}


.main_screener_v3 .dropdown {
  height: 40px;
  width: 295px;
  border-color: #A3AAC2;
  background-color: #ffffff;
  margin-left: 9%;
  padding-left: 1%;

  color: #A3AAC2;
  font-family: helvetica;
  font-weight: 400;
  font-size: 14;
}


.main_screener_v3 .ansDiv {
  padding-left: 7%;
  padding-right: 7%;
}

.main_screener_v3 .lineStyle {
  display: flex;
  height: 1px;
  background-color: #A3AAC2;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.main_screener_v3 .radioCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.main_screener_v3 .radioCon1 {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.main_screener_v3 .cross-img {
  width: 8px;
  height: 8px;
}

.main_screener_v3 .addAnswer {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: #124CE2
}

.main_screener_v3 .bottom-inner-con {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}


.main_screener_v3 .titleInput {
  height: 43px;
  width: 382px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background-color: #E8E9F34D;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  margin-left: 2%;
  padding-left: 1%;
}

.main_screener_v3 .buttonCon {
  display: flex;
  justify-content: center;
  margin-top: 23px;
}

.main_screener_v3 .button {
  background-color: #C7D4F2;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 202px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  margin-left: 7%;
}

.main_screener_v3 .button {
  background-color: #283ED4;
}

.main_screener_v3 .hideText {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 20px;
  color: #64697B;
}

.main_screener_v3 .whenCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main_screener_v3 .switchCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main_screener_v3 .dropdown1 {
  height: 40px;
  width: 100%;
  max-width: 180px;
  border-color: #A3AAC2;
  background-color: #ffffff;
  /* margin-left: 9%; */
  padding-left: 2.5%;
  border-radius: 5px;

  color: #5D5D62;
  font-family: helvetica;
  font-weight: 400;
  font-size: 14;
}

.main_screener_v3 .copyDeleteCon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* align-items: end; */
}

.main_screener_v3 .copy-img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}


.main_screener_v3 .QuestionBox {
  /* display: flex; */
  border: 1px solid #A3AAC2;
  width: 100%;
  max-width: 804px;
  /* height: 231px; */
  border-radius: 5px;
  margin-top: 3%;
  padding-top: 2%;
  padding-bottom: 5px;
  padding-left: 45px;
  padding-right: 45px;
  /* align-items: center; */
}

.main_screener_v3 .QuestionBox:first-child {
  margin-top: 0px;
}

.main_screener_v3 .addAnswer {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: #124CE2;
  cursor: pointer;
}

.main_screener_v3 .QuestionBox p.hideText {
  margin-top: 0px !important;
}

.main_screener_v3 .answer_del_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 22px;
}

.main_screener_v3 select.dropdown1 {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg fill="5D5D62" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 5px;
  border: 1px solid #5D5D62;
  border-radius: 2px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 22px;
  width: 100% !important;
  margin: 0px 9px;
}

.main_screener_v3 select.dropdown1:focus-visible {
  outline: 0 !important;
}

button.cstm_stdy_tb.cstm_stdy_prevsuccess.next_active.update_btn_cst.btn.btn-primary {
  width: 202px;
  max-width: 100%;
  height: 40px;
  max-height: 100%;
}

button.cstm_stdy_tb.cstm_stdy_prevsuccess.next_active.update_btn_cst.btn.btn-primary:hover {
  background-color: #283182;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .topLineCon {
    width: 33.33%;
  }

  .main_screener_v3 .QuestionBox {
    max-width: 90%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .main_screener_v3 .dropdown1 {
    max-width: 205px;
  }

  .main_screener_v3 .dropdown1 {
    margin: 0px 5px;
  }
}

@media only screen and (max-width: 767px) {
  .topText {
    font-size: 14px;
  }

  .main_screener_v3 .hideText {
    font-size: 16px;
  }

  .main_screener_v3 select.dropdown1 {
    font-size: 12px;
  }

  .main_screener_v3 .addAnswer {
    font-size: 13px;
  }

  .main_screener_v3 .copy-img {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 550px) {
  .main_screener_v3 .QuestionBox {
    padding-left: 10px;
    padding-right: 10px;
  }

  .topText {
    font-size: 12px;
  }

  .main_screener_v3 select.dropdown1 {
    width: 100% !important;
    max-width: 47% !important;
    margin: 5px;
  }

  .main_screener_v3 .whenCon {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 475px) {

  .main_screener_v3 select.dropdown1 {
    max-width: 100% !important;
  }

  .topLineCon {
    margin-left: 5px !important;
  }

  .topText {
    font-size: 11px;
  }
}

@media only screen and (max-width: 410px) {

  .topText {
    font-size: 10px;
  }

  .main_screener_v3 select.dropdown1 {
    font-size: 10px;
  }
}

@media only screen and (max-width: 350px) {
  .main_screener_v3 .wrapper {
    display: block;
  }
}

/* screenerv3 css end */

/* screener questions css start */
.main_screener_v2 .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_screener_v2 .wrapper {
  width: 100%;
  max-width: 975px;
  margin: auto;
}

.main_screener_v2 .wrapper.screner_scroll_active {
  height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.main_screener_v2 .screener_bx_option.multiple_choices {
  width: 100%;
}

.main_screener_v2 .screener_bx_option.multiple_choices .radioCon1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main_screener_v2 .screener_bx_option.multiple_choices .radioCon1 input {
  width: 100%;
  border: none;
  padding: 2px 40px;
  font-size: 20px;
  margin-bottom: 5px;
}

.main_screener_v2 .screener_bx_option.multiple_choices .radioCon1 input:hover {
  background: #F6F6FC;
  cursor: pointer;
  border-radius: 25px;
}

.main_screener_v2 .screener_bx_option.multiple_choices .radioCon1::before {
  content: '';
  background: white;
  width: 17px;
  height: 17px;
  border-radius: 25px;
  display: block;
  z-index: 9999999;
  position: absolute;
  left: 0;
  border: 1px solid #DADADA;
  margin-left: 8px;
}

.main_screener_v2 .screener_bx_option.multiple_choice {
  width: 10;
  width: 100%;
}

.main_screener_v2 .screener_bx_option.multiple_choice .radioCon1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main_screener_v2 .screener_bx_option.multiple_choice .radioCon1 input {
  width: 100%;
  border: none;
  padding: 2px 40px;
  font-size: 16px;
  margin-bottom: 5px;
  font-family: Helvetica;
}

.main_screener_v2 .screener_bx_option.multiple_choice .radioCon1 input:focus-visible {
  outline: none;
}

.main_screener_v2 .screener_bx_option.multiple_choice .radioCon1 input:hover {
  background: #F6F6FC;
  cursor: pointer;
  border-radius: 25px;
}

.main_screener_v2 .screener_bx_option.multiple_choice .radioCon1::before {
  content: '';
  background: white;
  width: 17px;
  height: 17px;
  border-radius: 25px;
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  border: 1px solid #DADADA;
  margin-left: 8px;
  margin-bottom: 0.6%;
}

.main_screener_v2 .screener_bx_option.check_boxes {
  width: 10;
  width: 100%;
}

.main_screener_v2 .screener_bx_option.check_boxes .radioCon1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main_screener_v2 .screener_bx_option.check_boxes .radioCon1 input {
  width: 100%;
  border: none;
  padding: 2px 40px;
  font-size: 16px;
  margin-bottom: 5px;
}

.main_screener_v2 .screener_bx_option.check_boxes .radioCon1 input:hover {
  background: #F6F6FC;
  cursor: pointer;
  border-radius: 25px;
}

.main_screener_v2 .screener_bx_option.check_boxes .radioCon1::before {
  content: '';
  background: white;
  width: 17px;
  height: 17px;
  /* border-radius: 25px; */
  display: block;
  z-index: 9999999;
  position: absolute;
  left: 0;
  border: 1px solid #DADADA;
  margin-left: 8px;
  margin-bottom: .6%;
}

.main_screener_v2 .screener_bx_option.check_boxes .radioCon1 input:focus-visible {
  outline: none;
}

.main_screener_v2 .screener_bx_option.multiple_choices .radioCon1:focus-visible::after {
  content: '';
  background-color: black;
  width: 12px;
  height: 12px;
  border-radius: 25px;
  display: block;
  z-index: 9999999;
  position: absolute;
  left: 9.5px;
  border: 1px solid #DADADA;
}

.screener_bx_option.multiple_choices img.cross_sign_std.img-thumbnail {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 0;
  margin-right: 5px;
  border: none;
  background-color: transparent;
}

.screener_bx_option.multiple_choice img.cross_sign_std.img-thumbnail {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 0;
  margin-right: 5px;
  border: none;
  background-color: transparent;
  margin-bottom: 0.6%;
}

.screener_bx_option.multiple_choices .main_screener_v2 .stydyServey {
  color: #1D1C1D;
  font-size: 35px;
  font-family: Helvetica;
  font-weight: 700;
  margin-top: 14.5%;
}

.screener_bx_option.check_boxes img.cross_sign_std.img-thumbnail {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  right: 0;
  margin-right: 5px;
  border: none;
  background-color: transparent;
  margin-bottom: .6%;
}

.main_screener_v2 .loremIpsum {
  color: #64697B;
  font-size: 12px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 21px;
  margin-top: -1%;
}

.main_screener_v2 .titleBox {
  display: flex;
  border: 1px solid #A3AAC2;
  width: 100%;
  max-width: 804px;
  height: 77px;
  margin-top: 1%;
  border-radius: 5px;
  align-items: center;
  padding: 10px 0px;
}

.main_screener_v2 .titleInput {
  height: 43px;
  width: 100%;
  max-width: 382px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background-color: #E8E9F34D;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  margin-left: 1.7%;
  padding-left: 1%;
  color: #000000;
  font-size: 16px;
  font-family: Helvetica;
}

h3.matr_col {
  font-size: 16px;
}

h3.matr_row {
  font-size: 16px;
}

.main_screener_v2 .BottombuttonCon {
  display: flex;
  justify-content: space-between;
  margin-top: 16.5%;
  padding-left: 3.8%;
  padding-right: 3.8%;
}

.main_screener_v2 button.add_screener_bx_option {
  width: 270px;
  height: 40px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #124CE2;
  margin-top: 10px;
  text-align: left;
  font-family: Helvetica;
}

.mtx_col .radioCon {
  margin-bottom: 0px !important;
}

.mtx_col .radioCon1 {
  margin-top: 0px !important;
}

.main_screener_v2 .Exitbutton {
  background-color: #ffffff;
  /* border: none; */
  border-width: 1px;
  border-color: #1D1C1D;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.main_screener_v2 .Nextbutton {
  background-color: #E8E9F3;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.main_screener_v2 .whenCon {
  display: flex;
  flex: 1;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding-left: 2.5%;
  padding-right: 6%;
  /* width: 804px; */
}

.main_screener_v2 .dropdownCon {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 6%;
}

.main_screener_v2 .dropdown1 {
  height: 40px;
  width: 100%;
  max-width: 210px;
  border-color: #A3AAC2;
  background-color: #ffffff;
  padding-left: 1%;
  border-radius: 5px;
  color: #A3AAC2;
  font-family: helvetica;
  font-weight: 400;
  font-size: 14px;
}

.main_screener_v2 .dropdown {
  height: 40px;
  width: 100%;
  max-width: 295px;
  border-color: #A3AAC2;
  background-color: #ffffff;
  margin-left: 7%;
  padding-left: 18px;
  color: #A3AAC2;
  font-family: helvetica;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg fill="5D5D62" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 5px;
  border: 1px solid #5D5D62;
  border-radius: 2px;
  font-size: 14px;
}


.main_screener_v2 .ansDiv {
  padding-left: 7%;
  padding-right: 7%;
}

.main_screener_v2 .lineStyle {
  display: flex;
  height: 1px;
  background-color: #A3AAC2;
  width: 98%;
  margin-top: 20px;
  margin-bottom: 2%;
  margin-left: auto;
  margin-right: auto;
}

.main_screener_v2 .radioCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.main_screener_v2 .mtx_col .radioCon1 {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  width: 100%;
  position: relative;
  align-items: center;
}

.main_screener_v2 .cross-img {
  width: 8px;
  height: 8px;
}

.main_screener_v2 .addAnswer {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: #124CE2
}

.main_screener_v2 .QuestionBox {
  /* display: flex; */
  border: 1px solid #A3AAC2;
  width: 100%;
  max-width: 804px;
  /* height: 231px; */
  margin-top: 1%;
  border-radius: 5px;
  padding-top: 29px;
  padding-bottom: 5px;
  /* align-items: center; */
  position: relative;
}

.main_screener_v2 .bottom-inner-con {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}


.main_screener_v2 .titleInput {
  height: 43px;
  width: 100%;
  max-width: 382px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  background-color: #E8E9F34D;
  border-bottom-width: 1px;
  border-bottom-color: #000000;
  margin-left: 2%;
  padding-left: 1%;
}

.main_screener_v2 .copyDeleteCon {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-right: 5px;
  margin-top: 10px;
  /* align-items: end; */
}


.main_screener_v2 .copy-img {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: 8px;
}

.screener_bx_option.matrix_rating_scale .radioCon1 input {
  width: 100% !important;
  max-width: 100% !important;
  background-color: #F6F6FC;
  border: none;
  padding: 2px 41px;
  font-size: 16px;
  font-family: Helvetica;
}

.screener_bx_option.matrix_rating_scale .radioCon1 input:focus-visible {
  outline: none;
}

.main_screener_v2 .screener_bx_option.matrix_rating_scale .radioCon1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0px;
  position: relative;
  align-items: center;
}

.main_screener_v2 .screener_bx_option.matrix_rating_scale .radioCon {
  margin-bottom: 0px;
}

.mtx_col .radioCon1 input {
  width: 100%;
  background-color: #F6F6FC;
  border: none;
  padding: 2px 41px;
  font-size: 16px;
  font-family: Helvetica;
}

.mtx_col .radioCon1 input:focus-visible {
  outline: none;
}

.main_screener_v2 .screener_bx_option.matrix_rating_scale .radioCon1::before {
  content: '';
  background: white;
  width: 17px;
  height: 17px;
  border-radius: 25px;
  display: block;
  z-index: 9999999;
  position: absolute;
  left: 0;
  border: 1px solid #DADADA;
  margin-left: 8px;
}

.mtx_col .radioCon1::before {
  content: '';
  background: white;
  width: 17px;
  height: 17px;
  border-radius: 25px;
  display: block;
  z-index: 9999999;
  position: absolute;
  left: 0;
  border: 1px solid #DADADA;
  margin-left: 8px;
}

.radioCon1.col_cst {
  position: relative;
  display: flex;
  align-items: center;
}

.main_screener_v2 .buttonCon {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.main_screener_v2 .button {
  background-color: #C7D4F2;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 202px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  margin-left: 7%;
}

.main_screener_v2 .button {
  background-color: #283ED4;
}

.main_screener_v2 .dropdown1:focus-visible {
  outline: 0px !important;
}

.screener_bx_option.matrix_rating_scale img.cross_sign_std.img-thumbnail {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  border: none;
  position: absolute;
  right: 0;
  background: transparent;
  margin-right: 5px;
}

.mtx_col img.cross_sign_std.img-thumbnail {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;
  border: none;
  position: absolute;
  right: 0;
  background: transparent;
  margin-right: 5px;
}

.cstm_witch_scenr_mdia {
  padding: 0px 0px 20px 0px;
}

.cstm_witch_scenr_mdia .form-check.form-switch {
  margin-left: 90px;
}

.quota-custom-switch {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  margin-right: 18px;
}

.quota-custom-switch label {
  margin-right: 14px;
}

.cstm_screener_media_op {
  padding: 0px 24px 15px;
}

@media only screen and (max-width: 950px) {
  .main_screener_v2 .QuestionBox {
    max-width: 90%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .main_screener_v2 .titleBox {
    max-width: 90%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .main_screener_v2 .dropdown {
    margin-left: 6%;
  }

  .main_screener_v2 .dropdown1 {
    margin: 0px 5px;
  }

  .main_screener_v2 .bottom-inner-con {
    padding: 0px 2%;
    display: flex;
    justify-content: flex-start;
  }

  .main_screener_v2 .QuestionBox .titleInput {
    margin-left: 0;
  }

  .main_screener_v2 .dropdown {
    margin-left: 2%;
  }

  .main_screener_v2 .QuestionBox .titleInput {
    margin-left: 0;
    width: 50%;
    max-width: unset;
    font-size: 16px;
  }

  .main_screener_v2 .screener_bx_option.multiple_choice .radioCon1 input {
    font-size: 18px;
  }

  .main_screener_v2 .dropdown {
    margin-left: 2%;
    width: 50%;
    max-width: unset;
  }
}

@media only screen and (max-width: 550px) {
  .main_screener_v2 .QuestionBox {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main_screener_v2 .titleBox {
    padding-left: 0px;
    padding-right: 0px;
  }

  .main_screener_v2 .dropdownCon {
    flex-wrap: wrap;
  }

  .main_screener_v2 .titleBox {
    height: max-content;
  }

  .main_screener_v2 .dropdown1 {
    margin: 5px 5px;
    max-width: unset;
  }

  .main_screener_v2 .QuestionBox .titleInput {
    margin: 0px;
    width: 100%;
    max-width: unset;
  }

  .titleBox .titleInput {
    margin: 10px;
  }

  .main_screener_v2 .titleBox .whenCon {
    margin: 10px 0px;
  }

  .main_screener_v2 .bottom-inner-con {
    flex-wrap: wrap;
  }

  .main_screener_v2 .QuestionBox .titleInput {
    width: 100%;
    margin-bottom: 10px;
  }

  .main_screener_v2 .dropdown {
    width: 100%;
    margin: 0px;
  }
}

/* screener questions css end */

/* survay css start from here */

.study_survey .innerCon {
  width: 63%
}

.study_survey .stydyServey {
  color: #1D1C1D;
  font-size: 35px;
  font-family: Helvetica;
  font-weight: 700;
  margin-top: 60px;
}

.study_survey .loremIpsum {
  color: #64697B;
  font-size: 16px;
  font-family: Helvetica;
  font-weight: 400;
  line-height: 21px;
  margin-top: -1%;
  margin-bottom: 5%;
  width: 100%;
  max-width: 700px;
}

.study_survey .lineStyle {
  background-color: #E8E9F3;
  height: 1px;
  width: 100%;
  max-width: 700px;
}

.study_survey .statsCon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  margin-top: .5%;
  margin-bottom: .5%;
}

.study_survey .titleSty {
  color: #A3AAC2;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  width: 20%;
  margin: 10px 0px;
}

.study_survey .linkStyle {
  display: flex;
  color: #4273F0;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.study_survey .preview {
  display: flex;
  color: #1D1C1D;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  margin: 10px 0px;
}

.cstm_survay_completed,
.cstm_screener_completed {
  height: 345px;
  display: block;
  max-height: 100%;
}

.wrapper.screner_scroll_active::-webkit-scrollbar {
  width: 15px;
}

.wrapper.screner_scroll_active::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
  border: 1px solid grey;
}

.wrapper.screner_scroll_active::-webkit-scrollbar-track {
  background: lightgrey;
  border-radius: 10px;
  margin-top: 20px;
}

@media (min-width:1400px) and (max-width:1920px) {
  .study_survey .statsCon:last-child {
    margin-bottom: 68px;
  }
}

@media only screen and (max-width: 1366px) {
  .study_survey .stydyServey {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 1200px) {
  .study_survey .innerCon {
    width: 60%;
  }

  .study_survey .linkStyle {
    font-size: 14px;
    line-break: anywhere;
  }

  .study_survey .preview {
    font-size: 14px;
  }
}

@media only screen and (max-width: 992px) {
  .study_survey .innerCon {
    width: 100%;
  }

  .study_survey .statsCon {
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .study_survey .linkStyle {
    width: 80%;
  }

  .cstm_home_App .complete-response {
    width: 25%;
  }

  .cstm_home_App .time-style {
    width: 25%;
  }
}


/* survay css end here */

/* participant facing css start */
.welcomeText {
  font-family: Helvetica;
  font-size: 50px;
  font-weight: 700;
  color: #090909;
  line-height: 57px;
  text-align: center;
}

.welcomeDesp {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  color: #5D5D62;
  max-width: 600px;
}

.lineStyle {
  height: 42vh;
  background-color: black;
  width: 1px;
  align-self: center;
}

.ScreenerTxt {
  font-family: Helvetica;
  font-size: 50px;
  font-weight: 700;
  color: #1D1C1D;
  line-height: 57px;
  text-align: left;
}

.takingScreener {
  font-family: Helvetica;
  font-size: 35px;
  font-weight: 700;
  color: #1D1C1D;
  line-height: 40px;
  text-align: left;
}

.takingScreenerDesp {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  color: #64697B;
  line-height: 40px;
  text-align: left;
  /* max-width: 35vw; */
}

.one-img {
  width: 50px;
  height: 50px;
  margin-bottom: 25px;
}

.bottomCon {
  display: flex;
  height: 14vh;
  justify-content: space-between;
  padding-left: 8vw;
  padding-right: 8vw;
}

.ExitbuttonScr1 {
  background-color: #fff;
  /* border: none; */
  border-width: 1px;
  border-color: #1D1C1D;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.NextbuttonScr1 {
  background-color: #4273F0;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

/* participant facing css end */

/* participant screener question */
.participant_fac_main_content .tab-content {
  width: 100%;
}

div#pf_sq_cstm-tabpane-5 {
  margin-top: 0px !important;
}

.pf_cstm_screener_main .topBarCon {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  height: 7vh;
}

.pf_cstm_screener_main ul.nav.nav-tabs {
  display: none;
}

.pf_cstm_screener_main .topBarQuestionActive {
  background-color: #4273F0;
  width: 77px;
  height: 5px;
  margin: 0px 8.5px;
  border-radius: 3px;
}

.pf_cstm_screener_main .topBarQuestion {
  background-color: #E8E9F3;
  width: 77px;
  height: 5px;
  margin: 0px 8.5px;
  border-radius: 3px;
}

.pf_cstm_screener_main .BoxCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.pf_study_inner_cstm.pf_cstm_screener_main .BoxCon {
  height: 100vh;
}

.pf_study_inner_cstm.pf_cstm_screener_main .BoxCon.pf_screener_ques {
  height: 80vh;
  margin-top: 63px;
  justify-content: flex-start;
}

.pf_cstm_screener_main .BoxScreenerQuestion {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #E8E9F3;
  max-width: 916px;
}

.pf_cstm_screener_main .questionCon {
  display: flex;
  /* height: 55px; */
  background-color: #E1E1E6;
  align-items: center;
}

.pf_cstm_screener_main .questionStyle {
  color: #090909;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 21px;
  margin-left: 20px;
  margin-top: 15px;
  word-break: break-word;
  margin: 0;
  padding: 20px;
}

.pf_cstm_screener_main .answerCon {
  display: flex;
  flex-direction: column;
  padding-top: 1vh;
  padding-bottom: 2.3vh;
  padding-left: 20px;
}

.answerCon.matrix_rating_scale {
  margin-top: 20px;
  padding-top: 0px;
  padding-bottom: 1.3vh;
}

.BoxScreenerQuestion .rows_colmns_screnr_pf table {
  width: 100%;
}

.answerCon.matrix_rating_scale table tbody tr:nth-child(odd) {
  border-radius: 3px;
  background: #F6F6FC;
}

.answerCon.matrix_rating_scale table thead tr th {
  padding-bottom: 6px;
  background: none;
  border: none;
  color: #090909;
  text-align: center;
  font-size: 20px;
  font-family: Helvetica;
  line-height: 24px;
  font-weight: 400;
}

.answerCon.matrix_rating_scale table tbody tr th {
  height: 36px;
  background: none;
  border: none;
  color: #090909;
  font-size: 20px;
  font-family: Helvetica;
  line-height: 24px;
  font-weight: 400;
}

.answerCon.matrix_rating_scale table tbody tr td {
  border: none;
}

.pf_cstm_screener_main .radioCon1 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.pf_cstm_screener_main .answerTxt {
  color: #5D5D62;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  margin-left: 12px;
  cursor: pointer;
}

.pf_cstm_screener_main .answerTxt::first-letter {
  text-transform: capitalize;
}

.pf_cstm_screener_main .bottomConScreenerQuestion {
  display: flex;
  height: 13vh;
  justify-content: space-between;
  padding-left: 4vw;
  padding-right: 4vw;
}

.pf_cstm_screener_main .Exitbutton {
  background-color: #fff;
  border-width: 1px;
  border-color: #1D1C1D;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
}

.pf_cstm_screener_main .Nextbutton {
  background-color: #E8E9F3;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
}

.pf_cstm_screener_main .NextbuttonActive {
  background-color: #283ED4;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
}

.pf_cstm_screener_main .NextbuttonActive:hover {
  background-color: #283182;
}

.pf_cstm_screener_main .NextbuttonActive.disabled {
  background: #E1E1E6;
}

.participant_fac_main_content .participant_question_image {
  background: transparent;
  width: 100% !important;
  max-width: 300px;
  height: 100%;
  object-fit: cover;
  margin-right: 0;
  margin-bottom: 15px;
}

.answerCon.multiple_choice .radioCon1 input[type="radio"] {
  position: relative;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.answerCon.check_boxes .radioCon1 input[type="checkbox"] {
  position: relative;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.answerCon.multiple_choice .radioCon1 input[type="radio"]:checked {
  background-color: #2196F3;
}

.answerCon.multiple_choice .radioCon1 input.active::after {
  content: '';
  position: absolute;
  z-index: 999999;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: grey;
}

.rows_colmns_screnr_pf table tr th {
  width: 18%;
}

.rows_colmns_screnr_pf table tr td>input {
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.rows_colmns_screnr_pf table tr td>input.active {
  position: relative;
}

.rows_colmns_screnr_pf table tr td input.active::after {
  content: '';
  position: absolute;
  z-index: 999999;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background: grey;
}

.answerCon.text_box .radioCon1 input[type="text"] {
  width: 100%;
  margin-right: 20px;
  padding-left: 12px;
  padding-right: 12px;
}

.answerCon.text_box .radioCon1 input[type="text"]:focus-visible {
  outline: none;
}

@media (min-width:1441px) and (max-width:1920px) {
  .participant_fac_main_content .pf_cstm_screener_main .questionCon {
    width: 100%;
  }
}

@media (max-width:1440px) {
  .pf_cstm_screener_main .bottomConScreenerQuestion {
    padding-left: 6%;
    padding-right: 6%;
  }

  .rows_colmns_screnr_pf table tr th {
    width: 17.8%;
  }

  .answerCon.matrix_rating_scale {
    margin-top: 14px;
  }
}

@media(max-width: 991px) {
  .answerCon.matrix_rating_scale {
    margin-top: 10px;
  }

  .answerCon.matrix_rating_scale table tbody tr th {
    height: 28px;
  }
}

@media(max-width: 900px) {
  .pf_cstm_screener_main .questionStyle {
    font-size: 16px;
  }

  .pf_cstm_screener_main .questionCon {
    height: 45px;
  }

  .participant_fac_main_content .participant_question_image {
    max-width: 180px;
    height: 180px;
  }
}

@media(max-width: 768px) {
  .participant_fac_main_content .participant_question_image {
    max-width: 140px;
    height: 140px;
  }

  .pf_cstm_screener_main .questionStyle {
    font-size: 14px;
  }

  .pf_cstm_screener_main .radioCon1 {
    margin-top: 5px;
  }

  .pf_cstm_screener_main .answerTxt {
    font-size: 11px;
  }

  .pf_cstm_screener_main .NextbuttonActive {
    font-size: 11px !important;
    width: 120px;
    height: 35px;
  }

  .pf_cstm_screener_main .Exitbutton {
    font-size: 11px !important;
    width: 120px;
    height: 35px;
  }

  .answerCon.multiple_choice .radioCon1 input[type="radio"] {
    height: 12px;
    width: 12px;
    margin-top: 1px;
  }

  .answerCon.check_boxes .radioCon1 input[type="checkbox"] {
    height: 12px;
    width: 12px;
    margin-top: 1px;
  }

  .answerCon.multiple_choice .radioCon1 input.active::after {
    width: 12px;
    height: 12px;
  }

  .rows_colmns_screnr_pf table tr td input.active::after {
    width: 12px;
    height: 12px;
  }

  .rows_colmns_screnr_pf table tr th {
    font-size: 12px;
  }

  .rows_colmns_screnr_pf table tr td>input.active {
    width: 12px;
    height: 12px;
  }

  .rows_colmns_screnr_pf table tr td>input {
    width: 12px;
    height: 12px;
  }

  .answerCon.matrix_rating_scale table thead tr th {
    font-size: 14px;
  }

  .answerCon.matrix_rating_scale table tbody tr th {
    font-size: 14px;
  }

  .answerCon.matrix_rating_scale {
    padding-right: 20px;
  }
}

@media(max-width: 550px) {
  .participant_fac_main_content .participant_question_image {
    max-width: 120px;
    height: 120px;
  }

  .pf_cstm_screener_main .questionCon {
    height: 25px;
  }

  .participant_fac_main_content .participant_question_image {
    max-width: 140px;
    height: 140px;
    margin-bottom: 10px;
  }

  .participant_question_image_Con {
    flex-direction: column !important;
    justify-content: center;
    align-items: center !important;
  }

  .rows_colmns_screnr_pf table tr th {
    width: 17.66%;
  }

  .answerCon.matrix_rating_scale {
    padding-right: 8px;
    padding-left: 8px;
  }

  .answerCon.matrix_rating_scale table thead tr th {
    font-size: 12px;
  }

  .answerCon.matrix_rating_scale table tbody tr th {
    font-size: 12px;
  }
}

@media(max-width: 450px) {
  .participant_fac_main_content .participant_question_image {
    max-width: 100px;
    height: 100px;
  }

  .pf_cstm_screener_main .answerCon {
    padding-top: 0.5vh;
    padding-bottom: 0.3vh;
  }

  .pf_cstm_screener_main .NextbuttonActive {
    width: 100px;
    height: 30px;
  }

  .pf_cstm_screener_main .Exitbutton {
    width: 100px;
    height: 30px;
  }

  .answerCon.text_box .radioCon1 input[type="text"] {
    margin-bottom: 10px;
  }
}

@media(min-width:320px) and (max-width:1400px) {

  .pf_cstm_screener_main .bottomConScreenerQuestion {
    padding-left: 0;
    padding-right: 0;
  }
}

​
/* end participant screener */

/* study practice css start here */
.pf_st_prtc_tb .BoxConPhase1 {
  display: flex;
  flex-direction: row;
  height: 80vh;
  margin-left: 9vh;
  margin-right: 9vh;
  margin-bottom: 7vh;
}

.pf_st_prtc_tb .leftConPhase1 {
  display: flex;
  flex-direction: column;
  flex: .17;
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  border-radius: 3px;
  background-color: #EAEAEA !important;
  flex: unset;
  width: 100%;
  max-width: 360px;
}

.cstm_box_counters {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.cstm_box_counters .leftConPhase1 {
  width: 100%;
  max-width: 1270px;
  height: 547px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 56px;
  line-height: 31px;
  font-weight: 700;
  color: white;
  background: #EAEAEA;
  padding-top: 0;
}

.cstm_box_counters .countdown_stdy_inner span.auto_comp_component {
  background: #283ED4;
  width: 121px;
  height: 121px;
  display: block;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
}

.pf_st_prtc_tb .RightConPhase1 {
  display: flex;
  flex: .83;
  flex-direction: column;
  background-color: #F6F6FC;
  margin-left: 2.5%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 6%;
  padding-right: 6%;
  border-radius: 3px;

}

.pf_st_prtc_tb .Phase1TxtStyle {
  font-family: Helvetica;
  font-size: 28px;
  font-weight: 700;
  color: #1D1C1D;
  margin-bottom: 9vh;
  display: none;
}

.pf_st_prtc_tb .leftTextUpper {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
}

.pf_st_prtc_tb .leftTextUpper span {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
}

.pf_st_prtc_tb .leftTextBold {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
}

.pf_st_prtc_tb .leftSideImg {
  max-width: 100%;
  height: 194px;
}

.pf_st_prtc_tb .RightSideImg {
  max-width: 100%;
  height: 194px;
}

.pf_st_prtc_tb .upperImgCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pf_st_prtc_tb .MidImgCon {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
}

.pf_st_prtc_tb .leftTextUpper3rd {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #090909;
}

.pf_st_prtc_tb .leftTextBold3rd {
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
}

.pf_st_prtc_tb .SandImgCon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf_st_prtc_tb .StxtBoxStyle {
  display: flex;
  width: 69px;
  height: 69px;
  background-color: #283ED4;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  border-radius: 5px;
}

.pf_st_prtc_tb .STextStyle {
  font-family: Helvetica;
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pf_st_prtc_tb .StxtBoxStyleLight {
  display: flex;
  width: 69px;
  height: 69px;
  background-color: #283ED4 !important;
  align-items: center;
  justify-content: center;
  margin-top: 4%;
  border-radius: 5px;
}

.pf_st_prtc_tb .tickIconImg {
  width: 98px;
  height: 98px;
  margin-bottom: 8%;
}

.pf_st_prtc_tb .MidImgTickCon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pf_st_prtc_tb .tickIconImgWaiting {
  width: 98px;
  height: 98px;
  margin-bottom: 8%;
}

.pf_st_prtc_tb .keyboardImgsty {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pf_st_prtc_tb {
  padding-top: 100px;
}

.copyDeleteCon.matrix_rating_scale {
  justify-content: space-between;
  padding-left: 7%;
  align-items: flex-end;
}

.ms-auto.cstm_rgt_std_btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.cstm_rgt_std_btn button {
  order: 1;
}

.cstm_time_media {
  margin-right: 20px;
  margin-bottom: 0px;
}

/* end participant study practice */

/* 404 page css start here */

.cls-1 {
  fill: #ffc541;
}

.cls-2 {
  fill: #4e4066;
}

.cls-3 {
  fill: #6f5b92;
}

.cls-4 {
  fill: #f78d5e;
}

.cls-5 {
  fill: #fa976c;
}

.cls-6,
.cls-7,
.cls-8 {
  fill: #b65c32;
}

.cls-10,
.cls-6 {
  opacity: 0.6;
}

.cls-7 {
  opacity: 0.4;
}

.cls-9 {
  fill: #f4b73b;
}

.cls-11 {
  fill: #f9c358;
}

.cls-12 {
  fill: #9b462c;
}

.cls-13 {
  fill: #aa512e;
}

.cls-14 {
  fill: #7d6aa5;
}


/* animations */

.wheel {
  animation: wheel-rotate 6s ease infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes wheel-rotate {
  50% {
    transform: rotate(360deg);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }

  100% {
    transform: rotate(960deg)
  }
}

.clock-hand-1 {
  animation: clock-rotate 3s linear infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

.clock-hand-2 {
  animation: clock-rotate 6s linear infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes clock-rotate {
  100% {
    transform: rotate(360deg)
  }
}

#box-top {
  animation: box-top-anim 2s linear infinite;
  transform-origin: right top;
  transform-box: fill-box;
}

@keyframes box-top-anim {
  50% {
    transform: rotate(-5deg)
  }
}

#umbrella {
  animation: umbrella-anim 6s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes umbrella-anim {
  25% {
    transform: translateY(10px) rotate(5deg);
  }

  75% {
    transform: rotate(-5deg);
  }
}

#cup {
  animation: cup-rotate 3s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  transform-origin: top left;
  transform-box: fill-box;
}

@keyframes cup-rotate {
  50% {
    transform: rotate(-5deg)
  }
}

#pillow {
  animation: pillow-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes pillow-anim {
  25% {
    transform: rotate(10deg) translateY(5px)
  }

  75% {
    transform: rotate(-10deg)
  }
}

#stripe {
  animation: stripe-anim 3s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes stripe-anim {
  25% {
    transform: translate(10px, 0) rotate(-10deg)
  }

  75% {
    transform: translateX(10px)
  }
}

#bike {
  animation: bike-anim 6s ease infinite;
}

@keyframes bike-anim {
  0% {
    transform: translateX(-1300px)
  }

  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }

  100% {
    transform: translateX(1300px)
  }
}

#rucksack {
  animation: ruck-anim 3s linear infinite;
  transform-origin: top;
  transform-box: fill-box;
}

@keyframes ruck-anim {
  50% {
    transform: rotate(5deg)
  }
}

.circle {
  animation: circle-anim ease infinite;
  transform-origin: center;
  transform-box: fill-box;
  perspective: 0px;
}

.circle.c1 {
  animation-duration: 2s
}

.circle.c2 {
  animation-duration: 3s
}

.circle.c3 {
  animation-duration: 1s
}

.circle.c4 {
  animation-duration: 1s
}

.circle.c5 {
  animation-duration: 2s
}

.circle.c6 {
  animation-duration: 3s
}

@keyframes circle-anim {
  50% {
    transform: scale(.2) rotateX(360deg) rotateY(360deg)
  }
}

.four,
#ou {
  animation: four-anim cubic-bezier(0.39, 0.575, 0.565, 1) infinite;
}

.four.a {
  transform-origin: bottom left;
  animation-duration: 3s;
  transform-box: fill-box;
}

.four.b {
  transform-origin: bottom right;
  animation-duration: 3s;
  transform-box: fill-box;
}

#ou {
  animation-duration: 6s;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes four-anim {
  50% {
    transform: scale(.98)
  }
}

/* 404 page css end here */
/* loading animation start */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 80px;
  height: 80px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #4273F0;
  border-color: #4273F0 transparent #4273F0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.cstm_loader {
  text-align: center;
}

.cstm_loader {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cstm_seeMore.cstm_loader {
  position: relative;
  transform: translate(-31%, 9%);
}

.cstm_seeMore .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 23px;
  height: 23px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}




@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* loading animation end here */



@media only screen and (min-width:992px) {

  .navbar_cstm div#responsive-navbar-nav {
    padding-left: 20px;
  }

  .navbar_cstm div#responsive-navbar-nav a.nav-link {
    margin-left: 50px;
  }

  .navbar_cstm {
    background: #1D1C1D;
    padding: 0;
  }
}

/* participants facing page css */
.participant_fac_main_content.container ul.nav.nav-tabs {
  display: none !important;
}

.participant_fac_main_cstm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
}

.disable_active_btn {
  background: #C7D4F2 !important;
}

.participant_fac_main_cstm button.button {
  width: 204px;
  max-width: 100%;
  height: 40px;
  max-height: 100%;
  border: none;
  background-color: #283ED4;
  color: #F4F6FC;
  font-size: 12px;
  border-radius: 5px;
  font-weight: 700;
}

.participant_fac_main_cstm button.button:hover {
  background-color: #283182;
}

@media only screen and (max-width: 1000px) {
  .welcomeText {
    font-size: 40px;
  }

  .welcomeDesp {
    font-size: 15px;
    max-width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .welcomeText {
    font-size: 35px;
  }

  .welcomeDesp {
    line-height: 30px;
    max-width: 80%;
  }

  .participant_fac_main_cstm button.button {
    width: 170px;
    height: 35px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 470px) {
  .welcomeText {
    font-size: 25px;
    line-height: 25px;
  }

  .welcomeDesp {
    font-size: 14px;
    max-width: 90%;
  }
}

.pf_cstm_second_main {
  display: flex;
  flex-direction: column;
}

.pf_cstm_second_main .upperCon {
  display: flex;
  flex-direction: row;
  height: 78vh;
}

.pf_cstm_second_main .column {
  flex: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pf_cstm_second_main .column1 {
  flex: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 80px;
}

.pf_cstm_second_main .lineStyle {
  height: 368px;
  background-color: black;
  width: 1px;
  align-self: center;
}

.pf_cstm_second_main .ScreenerTxt {
  font-family: Helvetica;
  font-size: 50px;
  font-weight: 700;
  color: #090909;
  line-height: 57px;
  text-align: left;
  margin-bottom: 28px;
}

.pf_cstm_second_main .takingScreener {
  font-family: Helvetica;
  font-size: 35px;
  font-weight: 700;
  color: #090909;
  line-height: 40px;
  text-align: left;
  margin-bottom: 28px;
}

.pf_cstm_second_main .takingScreenerDesp {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  color: #5D5D62;
  line-height: 40px;
  text-align: left;
  /* max-width: 35vw; */
}

@media only screen and (max-width: 1200px) {
  .pf_cstm_second_main .column1 {
    margin-left: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .pf_cstm_second_main .ScreenerTxt {
    font-size: 30px;
    margin-bottom: 15px;
  }

  p.pf_noCirle {
    width: 40px;
    height: 40px;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 35px;
  }

  .pf_cstm_second_main .takingScreener {
    font-size: 30px;
  }

  .pf_cstm_second_main .takingScreenerDesp {
    font-size: 15px;
    line-height: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .pf_cstm_second_main .takingScreener {
    font-size: 22px;
    line-height: 10px;
  }

  .pf_cstm_second_main .ScreenerTxt {
    font-size: 22px;
    margin-bottom: 0px;
    line-height: 50px;
  }

  .pf_cstm_second_main .lineStyle {
    height: 30vh;
  }

  .pf_cstm_second_main .takingScreenerDesp {
    font-size: 12px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 425px) {
  .pf_cstm_second_main .column1 {
    margin-left: 25px;
  }

  .pf_cstm_second_main .takingScreener {
    font-size: 20px;
    margin-bottom: 20px;
  }

  p.pf_noCirle {
    width: 30px;
    height: 30px;
    font-size: 15px;
    margin-bottom: 25px;
  }

  .cstm_stdy_tb.cstm_stdy_prevsuccess.next_active.btn.btn-primary {
    max-width: 110px;
    height: 35px;
    font-size: 10px;
  }

  button.success.ms-auto.cstm_stdy_tb.cstm_stdy_next.btn.btn-primary {
    max-width: 110px;
    height: 35px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 370px) {
  .pf_cstm_second_main .takingScreener {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .pf_cstm_second_main .takingScreenerDesp {
    font-size: 10px;
  }

  .pf_cstm_second_main .ScreenerTxt {
    font-size: 18px;
    margin-bottom: 0px;
    line-height: 40px;
  }

  .pf_cstm_second_main .lineStyle {
    height: 22vh;
  }
}

@media only screen and (max-width: 340px) {
  .pf_cstm_second_main .takingScreener {
    font-size: 16px;
  }

  .pf_cstm_second_main .takingScreenerDesp {
    font-size: 9px;
  }

  .pf_cstm_second_main .ScreenerTxt {
    font-size: 16px;
    line-height: 32px;
  }
}

/* participant facing page css end */

/* participant study completed css */

.pf_comp_stdy_main .BoxComp {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1091px;
  height: 100%;
  max-height: 388px;
  align-self: center;
  border: 1px solid #E8E9F3;
  background-color: #283ED4;
  border-radius: 4px;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 7.5vh;
  padding-bottom: 5vh;
  position: relative;
}

.pf_comp_stdy_main .BoxCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 92vh;
}

.pf_comp_stdy_main .textConComp {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pf_comp_stdy_main .congTextComp {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  max-width: 60%;
  margin-top: 35px;
  margin-bottom: 6px;
}

.pf_comp_stdy_main .lorumTextComp {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  max-width: 57%;
}

.pf_comp_stdy_main .Exitbutton {
  background-color: #fff;
  border-width: 1px;
  border-color: #1D1C1D;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  width: 257px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
  border: none;
  position: absolute;
  right: 6.5%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.pf_st_first .BoxCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.pf_st_first .Box {
  display: flex;
  flex-direction: column;
  /* width: 748px; */
  width: 51vw;
  align-self: center;
  border: 1px solid #E8E9F3;
  /* height: 388px; */
  background-color: #283ED4;
  border-radius: 4px;
  padding-left: 47px;
  padding-right: 47px;
  padding-top: 60px;
  padding-bottom: 1.5vh;
}

.pf_st_first .beforeStartText {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 0px;
}


.pf_st_first .theNextPageText {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.pf_st_first .taskDesignTxt {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  margin-top: 52px;
  margin-bottom: 57px;
}

.pf_st_first .bottomConbtn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pf_st_first .Nextbutton {
  background-color: #4273F0;
  border: none;
  color: white;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1vh;

  width: 146px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

p.pf_noCirle {
  background: #283ED4;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 35px;
  font-family: 'Helvetica';
}

.participant_fac_main_content .cstm_stdy_tb.cstm_stdy_next {
  font-family: Helvetica;
  width: 100%;
  max-width: 146px;
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  background: #283ED4;
  border: none;
}

.participant_fac_main_content .cstm_stdy_tb.cstm_stdy_next:hover {
  background-color: #283182;
}

@media(max-width: 767px) {
  p.pf_noCirle {
    width: 35px !important;
    height: 35px !important;
    font-size: 15px;
  }
}

@media(max-width: 550px) {
  .pf_cstm_second_main .takingScreener {
    line-height: 30px;
    margin-bottom: 15px;
  }
}

@media(max-width: 370px) {
  p.pf_noCirle {
    width: 30px !important;
    height: 30px !important;
    font-size: 13px;
  }
}

/* participant study completed css end here */

/* advance config */
.Advance_config_btn_Con {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.advance_config_btn {
  width: 100%;
  max-width: 142px;
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  font-family: Helvetica;
  border: 1px #5D5D62 solid;
  background: transparent;
  color: #5D5D62;
  border-radius: 5px;
}

.MediaPopUpMainCon {
  display: flex;
  position: absolute;
  height: 90%;
  width: 90%;
  margin-top: -40%;
  margin-left: -5%;
  align-self: center;
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  align-items: center;
}

.MediaModalCon {
  background-color: #D2D2D2;
  display: flex;
  width: 411px;
  height: 300px;
  padding: 1%;
  flex-direction: column;
  /* align-items: center; */
  align-items: center;
}

.modalCrossIconCon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.modalCrossIconSty {
  width: 20px;
  height: 20px;
}

.mediaModalTitleSty {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.MediaInputFieldModalCon {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}

.mediaModalInputFieldTitleSty {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.mediaModalInputFieldSty {
  width: 97px;
  height: 23px;
  border: 1px solid #5D5D62;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  color: #030303
}

.mainResultStudyCon {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* advance config end*/


/* you dont qualify css */

.pf_comp_dont_qualify .BoxCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.pf_comp_dont_qualify .BoxComp {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 705px;
  align-self: center;
  border: 1px solid #E8E9F3;
  background-color: #283ED4;
  border-radius: 4px;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 105px;
  padding-bottom: 6vh;
  height: 100%;
  max-height: 397px;
}

.pf_comp_dont_qualify .congTextComp {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 50px;
  line-height: 57px;
  max-width: 80%;
  display: flex;
  align-items: flex-end;
}

.pf_comp_dont_qualify .lorumTextComp {
  color: #FFFFFF;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  max-width: 57%;
  margin-top: -15px;
}

.pf_comp_dont_qualify .Exitbutton {
  background-color: #fff;
  color: #090909;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  width: 257px;
  min-height: 40px;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
  align-self: center;
  margin-top: 25px;
  border: none;
}

@media(max-width: 768px) {
  .pf_comp_dont_qualify .congTextComp {
    max-width: 100%;
  }

  .pf_comp_dont_qualify .BoxComp {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
    max-height: 340px;
  }
}

@media(max-width: 550px) {
  .pf_comp_dont_qualify .congTextComp {
    font-size: 40px;
  }

  .pf_comp_dont_qualify .BoxComp {
    padding-top: 30px;
    max-height: 300px;
  }
}

@media(max-width: 470px) {
  .pf_comp_dont_qualify .congTextComp {
    font-size: 28px;
    line-height: 40px;
    max-height: 280px;
  }

  .pf_comp_dont_qualify .lorumTextComp {
    max-width: 100%;
  }
}

/* screener image  */

.participant_question_image_Con {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.participant_question_image {
  height: "100%";
  width: 160px;
  margin-right: 10px;
}

/* before you start page css */
.participant_fac_main_content .pf_st_first .Box {
  width: 100%;
}

.pf_study_inner_cstm.pf_cstm_screener_main .pf_st_first .taskDesignTxt {
  width: 100%;
  max-width: 619px;
}

div#pf_sq_cstm-tabpane-1 .pf_st_first .taskDesignTxt {
  margin-bottom: 57px;
}

div#pf_sq_cstm-tabpane-2 .pf_st_first .taskDesignTxt {
  margin-bottom: 57px;
}

.pf_study_inner_cstm.pf_cstm_screener_main .pf_st_first .taskDesignTxt span {
  font-size: 24px !important;
}

.pf_st_first .bottomConbtn {
  margin-top: 26px;
}

.participant_fac_main_content .bottomConbtn button.Exitbutton.text-center {
  width: 100%;
  max-width: 257px;
  margin: auto;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
}

.participant_fac_main_content .bottomConbtn button.Nextbutton.text-center {
  width: 100%;
  max-width: 257px;
  margin: auto;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  border: none;
  font-style: italic;
  text-transform: capitalize;
  background: transparent;
}

@media(min-width: 1201px) and (max-width: 2500px) {
  .participant_fac_main_content .pf_st_first .Box {
    max-width: 819px;
    height: 100%;
    max-height: 462px;
  }
}

@media(max-width: 1200px) {
  .participant_fac_main_content .pf_st_first .taskDesignTxt {
    width: 100%;
    max-width: 100%;
  }

  .participant_fac_main_content .pf_st_first .Box {
    width: 100%;
    max-width: 650px;
  }
}

@media(max-width: 991px) {
  .participant_fac_main_content .pf_st_first .Box {
    width: 100%;
    max-width: 550px;
  }

  .participant_fac_main_content .pf_st_first .beforeStartText {
    font-size: 35px;
  }

  .participant_fac_main_content .pf_st_first .taskDesignTxt {
    color: #FFFFFF;
    font-family: Helvetica;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    margin-top: 5vh;
  }

  .participant_fac_main_content .bottomConbtn button.Exitbutton.text-center {
    max-width: 180px;
    height: 35px;
  }

  .pf_study_inner_cstm.pf_cstm_screener_main .pf_st_first .taskDesignTxt span {
    font-size: 18px !important;
  }
}

@media(max-width: 600px) {
  .participant_fac_main_content .pf_st_first .Box {
    width: 95%;
    max-width: 100%;
  }
}

@media(max-width: 550px) {
  .participant_fac_main_content .pf_st_first .beforeStartText {
    font-size: 30px;
  }

  .participant_fac_main_content .pf_st_first .taskDesignTxt {
    font-size: 16px;
    margin-top: 10px;
  }

  .pf_study_inner_cstm.pf_cstm_screener_main .pf_st_first .taskDesignTxt span {
    font-size: 16px !important;
  }
}

@media(max-width: 425px) {
  .participant_fac_main_content .pf_st_first .beforeStartText {
    font-size: 25px;
  }
}

/* before you start page css end */

/* phases implicit css start */
.participant_fac_main_content .pf_st_prtc_tb .leftTextUpper {
  background: #EAEAEA;
}

.participant_fac_main_content .pf_st_prtc_tb .RightConPhase1 {
  background-color: #EAEAEA !important;
  width: 100%;
  max-width: 887px;
  height: 547px;
  margin-left: 26px;
  flex: unset;
  position: relative;
}

.participant_fac_main_content .BoxConPhase1 {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  margin: auto;
}

.pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 {
  width: 100%;
  max-width: 1270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  background: #EAEAEA;
  height: 547px;
  padding: 4% 0px;
  position: relative;
}

.pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 9%;
  margin-top: -25px;
}

.pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
  width: 100%;
  max-width: 383px;
  height: 170px;
  object-fit: contain;
}

.stdy_tb_5.cstm_line_top {
  height: 9px;
  background: #EAEAEA;
  width: 100%;
  max-width: 831px;
  margin: 50px auto 0px auto;
  border-radius: 4px;
}

.cstm_Tp_ln {
  background-color: #283ED4;
  display: block;
  height: 100%;
  border-radius: 4px;
}

.pf_st_rel_tab_main .MidImgCon img.tickIconImg {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pf_st_rel_tab_main .MidImgCon img.tickIconImgWaiting {
  width: 98px;
  height: 98px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
  position: relative;
  width: 100%;
  max-width: 383px;
  top: 30px;
  height: 170px;
  object-fit: contain;
}

.pf_cstm_screener_main.pf_survey_ques .BoxScreenerQuestion .answerCon.multiple_choices .radioCon1 input {
  position: absolute;
  top: 1px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.pf_cstm_screener_main.pf_survey_ques .answerCon.matrix_rating_scale {
  padding-right: 20px;
}

.pf_cstm_screener_main.pf_survey_ques .rows_colmns_screnr_pf table tr th {
  width: 16%;
  height: 37px;
}

.pf_cstm_screener_main.pf_survey_ques .BoxCon {
  height: 100%;
  margin: 25px 0px;
}

.pf_full_complete_main.pf_comp_stdy_main .BoxComp {
  width: 100%;
  max-width: 705px;
  height: 100%;
  max-height: 397px;
  padding: 2% 61px;
}

.pf_full_complete_main.pf_comp_stdy_main .textConComps .congTextComp {
  width: 100%;
  max-width: 470px;
}

.pf_full_complete_main.pf_comp_stdy_main .textConComps p {
  font-family: Helvetica;
  color: white;
  font-size: 18px;
  line-height: 40px;
  font-weight: 400;
}

.pf_full_complete_main.pf_comp_stdy_main .Exitbutton {
  width: 100%;
  max-width: 257px;
  margin: auto;
  display: block;
  margin-top: 24px;
  position: absolute;
  right: 0;
  left: 0;
  top: unset;
  bottom: 10%;
}

.study_rel.pf_st_rel_tab_main p.target_txt_pairing {
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  text-transform: capitalize;
}

.pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.lft_txt_pairing {
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  text-transform: capitalize;
  font-family: Helvetica;
}

.pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.right_txt_pairing {
  font-size: 50px;
  font-weight: 400;
  line-height: 50px;
  text-transform: capitalize;
  font-family: Helvetica;
}

@media(max-width: 1366px) {
  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
    max-width: 300px;
    height: 150px;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    max-width: 300px;
    height: 150px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImg {
    width: 75px;
    height: 75px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImgWaiting {
    width: 75px;
    height: 75px;
  }
}

@media(max-width: 1200px) {
  .participant_fac_main_content .pf_st_prtc_tb .leftSideImg {
    width: 120px;
    height: 145px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightSideImg {
    width: 145px;
    height: 145px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightConPhase1 {
    padding: 20px 35px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .leftTextUpper3rd {
    font-size: 22px;
  }

  .pf_st_prtc_tb .leftTextBold3rd {
    font-size: 22px;
  }

  .pf_st_prtc_tb .leftTextUpper {
    font-size: 22px;
  }

  .pf_st_prtc_tb .leftTextUpper span {
    font-size: 22px;
  }

  .pf_st_prtc_tb .tickIconImgWaiting {
    width: 90px;
    height: 90px;
  }

  .cstm_box_counters .countdown_stdy_inner span.auto_comp_component {
    width: 90px;
    height: 90px;
    font-size: 35px;
  }

  .pf_comp_stdy_main .congTextComp {
    font-size: 40px;
    line-height: 45px;
  }

  .pf_comp_stdy_main .lorumTextComp {
    font-size: 16px;
    line-height: 35px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .BoxComp {
    max-height: 300px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
    max-width: 280px;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    max-width: 280px;
  }

  .study_rel.pf_st_rel_tab_main p.target_txt_pairing {
    font-size: 30px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.lft_txt_pairing {
    font-size: 30px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.right_txt_pairing {
    font-size: 30px;
  }
}

@media(max-width: 991px) {
  .participant_fac_main_content .BoxConPhase1 {
    flex-direction: column;
  }

  .pf_st_prtc_tb .leftConPhase1 {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightConPhase1 {
    margin-left: 0px;
  }

  .pf_st_prtc_tb .tickIconImgWaiting {
    width: 70px;
    height: 70px;
  }

  .cstm_box_counters .leftConPhase1 {
    height: 450px;
  }

  .pf_comp_stdy_main .congTextComp {
    font-size: 35px;
    line-height: 40px;
  }

  .pf_comp_stdy_main .lorumTextComp {
    font-size: 14px;
    line-height: 32px;
  }

  .pf_comp_stdy_main .Exitbutton {
    width: 215px;
    height: 35px;
  }

  .pf_comp_stdy_main .BoxComp {
    max-height: 340px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .textConComps .congTextComp {
    font-size: 45px;
    line-height: 60px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
    max-width: 180px;
    height: 100px;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    max-width: 180px;
    height: 100px;
    top: 30px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImg {
    width: 75px;
    height: 75px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImgWaiting {
    width: 75px;
    height: 75px;
  }

  .study_rel.pf_st_rel_tab_main p.target_txt_pairing {
    font-size: 24px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.lft_txt_pairing {
    font-size: 24px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.right_txt_pairing {
    font-size: 24px;
  }
}

@media(max-width: 768px) {
  .participant_fac_main_content .pf_st_prtc_tb .leftTextUpper3rd {
    font-size: 18px;
  }

  .pf_st_prtc_tb .leftTextBold3rd {
    font-size: 18px;
  }

  .pf_st_prtc_tb .leftTextUpper {
    font-size: 18px;
  }

  .pf_st_prtc_tb .leftTextUpper span {
    font-size: 18px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .leftSideImg {
    width: 105px;
    height: 115px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightSideImg {
    width: 115px;
    height: 115px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightConPhase1 {
    height: 400px;
  }

  .pf_st_prtc_tb .StxtBoxStyle {
    width: 55px;
    height: 55px;
  }

  .pf_st_prtc_tb .tickIconImgWaiting {
    width: 50px;
    height: 50px;
  }

  .cstm_box_counters .leftConPhase1 {
    height: 370px;
  }

  .cstm_box_counters .countdown_stdy_inner span.auto_comp_component {
    width: 70px;
    height: 70px;
    font-size: 25px;
  }

  .pf_cstm_screener_main.pf_survey_ques .BoxScreenerQuestion .answerCon.multiple_choices .radioCon1 input {
    top: 2px;
    height: 12px;
    width: 12px;
  }

  .pf_cstm_screener_main.pf_survey_ques .rows_colmns_screnr_pf table tr th {
    height: 30px;
  }

  .answerCon.text_box .radioCon1 input[type="text"] {
    font-size: 13px;
  }

  .pf_comp_stdy_main .BoxComp {
    max-height: 300px;
  }

  .pf_comp_stdy_main .congTextComp {
    font-size: 28px;
    line-height: 40px;
  }

  .pf_comp_stdy_main .lorumTextComp {
    font-size: 12px;
    line-height: 26px;
  }

  .pf_comp_stdy_main .Exitbutton {
    width: 180px;
    height: 30px;
    font-size: 11px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .textConComps .congTextComp {
    font-size: 32px;
    line-height: 36px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .textConComps p {
    line-height: 32px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 {
    height: 430px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
    max-width: 150px;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    max-width: 150px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImg {
    width: 60px;
    height: 60px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImgWaiting {
    width: 60px;
    height: 60px;
  }

  .study_rel.pf_st_rel_tab_main p.target_txt_pairing {
    line-height: 30px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.lft_txt_pairing {
    line-height: 30px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.right_txt_pairing {
    line-height: 30px;
  }

  .pf_st_prtc_tb .StxtBoxStyleLight {
    width: 50px;
    height: 50px;
  }

  .pf_st_prtc_tb .STextStyle {
    font-size: 18px;
    margin-bottom: 0px;
  }
}

@media(max-width: 550px) {
  .participant_fac_main_content .pf_st_prtc_tb .leftTextUpper3rd {
    font-size: 16px;
  }

  .pf_st_prtc_tb .leftTextBold3rd {
    font-size: 16px;
  }

  .pf_st_prtc_tb .leftTextUpper {
    font-size: 16px;
  }

  .pf_st_prtc_tb .leftTextUpper span {
    font-size: 16px;
  }

  .cstm_box_counters .leftConPhase1 {
    height: 300px;
  }

  .cstm_box_counters .countdown_stdy_inner span.auto_comp_component {
    width: 55px;
    height: 55px;
    font-size: 23px;
  }

  .pf_comp_stdy_main .Exitbutton {
    width: 180px;
    height: 30px;
    font-size: 11px;
  }

  .pf_comp_stdy_main .textConComp {
    justify-content: center;
  }

  .pf_comp_stdy_main .textConComp .Exitbutton {
    position: absolute;
    bottom: 15%;
    top: unset;
    right: unset;
  }

  .pf_comp_stdy_main .lorumTextComp {
    width: 100%;
    max-width: 100%;
  }

  .pf_comp_stdy_main .BoxComp {
    max-height: 250px;
    padding-top: 2.5vh;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon {
    margin-top: 0px;
    padding: 0px 5%;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    top: 0px;
  }

}

@media(max-width: 480px) {
  .pf_full_complete_main.pf_comp_stdy_main .textConComps .congTextComp {
    font-size: 28px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .textConComps p {
    font-size: 16px;
    line-height: 30px;
  }

  .study_rel.pf_st_rel_tab_main p.target_txt_pairing {
    font-size: 22px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.lft_txt_pairing {
    font-size: 22px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.right_txt_pairing {
    font-size: 22px;
  }
}

@media(max-width: 420px) {
  .participant_fac_main_content .pf_st_prtc_tb .leftSideImg {
    width: 80px;
    height: 95px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightSideImg {
    width: 90px;
    height: 90px;
  }

  .pf_st_prtc_tb .StxtBoxStyle {
    width: 55px;
    height: 55px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightConPhase1 {
    height: 350px;
  }

  .cstm_box_counters .leftConPhase1 {
    height: 250px;
  }

  .cstm_box_counters .countdown_stdy_inner span.auto_comp_component {
    width: 45px;
    height: 45px;
    font-size: 18px;
  }

  .pf_comp_stdy_main .congTextComp {
    font-size: 24px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .BoxComp {
    max-height: 300px;
    padding-top: 4%;
    padding: 2% 25px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 {
    height: 330px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
    max-width: 120px;
    height: 125px;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    max-width: 120px;
    height: 125px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImg {
    width: 45px;
    height: 45px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImgWaiting {
    width: 45px;
    height: 45px;
  }
}

@media(max-width: 350px) {
  .pf_full_complete_main.pf_comp_stdy_main .textConComps .congTextComp {
    font-size: 22px;
    line-height: 40px;
    margin-top: 10px;
  }

  .pf_full_complete_main.pf_comp_stdy_main .BoxComp {
    max-height: 300px;
    padding-top: 9%;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 .upperImgCon img {
    max-width: 100px;
  }

  .pf_st_rel_tab_main .MidImgCon img.leftSideImg.target_img_pairing {
    max-width: 100px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImg {
    width: 35px;
    height: 35px;
  }

  .pf_st_rel_tab_main .MidImgCon img.tickIconImgWaiting {
    width: 35px;
    height: 35px;
  }
}

/* phases implicit css end */

/*  generate button css start  */

.study_main_content_cstm.false.container .num_of_participant_Con .generate_Link_Btn {
  width: 100% !important;
  max-width: 410px !important;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  border-color: #11208B;
  margin-top: 0 !important;
  background: #11208B;
  color: white;
  height: 40px;
}

.study_main_content_cstm.false.container .num_of_participant_Con input.num_of_participant_input {
  width: 100%;
  max-width: 111px;
  height: 40px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #090909;
  border-radius: 6px;
  border: 1px solid #090909;
  padding-left: 10px;
}

.num_of_participant_Con .vstack div.hstack .generate_Link_Btn.btn.btn-outline-secondary {
  width: 100% !important;
  max-width: 276px !important;
  margin-left: 22px;
  height: 40px;
}

.participant_fac_main_content.container button.generate_Link_Btn {
  width: 100%;
  max-width: 400px;
  height: 40px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: white;
  border-color: #11208B;
  background-color: #11208B;
  margin: 0;
  margin-top: 0px !important;
}

.pf_gropID_pg .vstack {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.participant_fac_main_content.container input.num_of_participant_input {
  width: 100%;
  max-width: 110px;
  height: 40px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #090909;
  border-color: #090909;
  border-radius: 6px;
  border: 1px solid #090909;
}

.gpnlnk_indv .hstack {
  gap: unset !important;
}

.pf_gropID_pg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 470px;
  transform: translate(-50%, -50%);
  padding: 0px 10px;
}

/*  generate button css end  */
/* group participant id  start */
.pf_gropID_pg {
  padding: 50px;
  max-width: 50%;
  margin: auto;
}

/* group participant id  end */


/* result page css start */

.mainResultStudyCon {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
}

/* in case of filter flex:1 else .87 */

.BoxConResultMain {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding-top: 4%;
  padding-bottom: 2%;
  padding-right: 1%;
  padding-left: 1%;
  flex: 1 1;
  justify-content: center;
}

.resultReateNewStudyMainCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 8.5%;
  padding-left: 22%;
}

.resultReateGenerateBtnCon {
  display: flex;
  flex-direction: row;
}

.resultReateGenerateLinkeBtnCon {
  display: flex;
  flex-direction: row;
}

.resultReateGenerateLinkeBtnCon1 {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
}

.num_of_participant_inputDashboard {
  width: 100%;
  max-width: 110px;
  height: 40px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: center;
  color: #090909;
  border-color: #090909;
  border-radius: 6px;
  border: 1px solid #090909;

}

.resultGenerateLinksBtnSty {
  width: 230px;
  text-align: center;
  border-color: #090909;
  background-color: transparent;
  color: #090909;
  font-size: 12px;
  font-weight: 100;
  height: 40px;
}

.leftConResultMain {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 3px;
  width: 100%;
  max-width: 175px;
  max-height: 496px;
}

.RightConResultMain {
  display: flex;
  flex-direction: column;
  background-color: #F0F0F3;
  border-radius: 3px;
  padding-top: 20px;
  width: 87%;
  max-height: 100%;
  padding-bottom: 21px;
  position: relative;
}

.ResultTabCon {
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  width: 84%;
  align-self: center;
  border-radius: 4px;
  height: 56px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.8vh;
  padding: 0px 2px;
}

.ResultTabTxtCon {
  height: 40px;
  width: 165px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
  border: none;
}

button.ResultTabTxtCon:hover {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset;
}

.resultStudyAttributeCon.mediaAttriImg.mediaBrandImg+.resultUpdateScreenerBtnCon {
  position: relative;
  right: 0;
  bottom: 0;
}

.resultStudyAttributeCon.mediaAttriText.mediaBrandText+.resultUpdateScreenerBtnCon {
  position: relative;
  justify-content: flex-end;
}

.resultStudyAttributeCon.mediaAttriText.mediaBrandImg+.resultUpdateScreenerBtnCon {
  position: relative;
  justify-content: flex-end;
}

.resultStudyAttributeCon.mediaAttriImg.mediaBrandText+.resultUpdateScreenerBtnCon {
  position: absolute;
  right: 29px;
  bottom: 29px;
}

/* button.ResultTabTxtCon:focus:not(:focus-visible) {
  border: 2px solid white;
  border-radius: 6px;
} */
.ResultTabText {
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 400;
  color: #1D1C1D;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
}

.ResultTabRenderCon {
  display: flex;
  height: 100%;
  width: 88%;
  align-self: center;
  flex-direction: column;
}

.searchBarCon {
  width: 161px;
  height: 34px;
  border: 1px solid #E8E9F3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  padding-left: 5px;
  padding-right: 12px;
  margin-right: 7px;
  margin-bottom: 10vh;
}

.searchIconSty {
  width: 13px;
  height: 14px;
}

.searchInputSty {
  background-color: #F0F0F3;
  border-radius: 16px;
  width: 112px;
  height: 26px;
  border: none;
  padding-left: 13px;
  margin: 5px 0px;
}

.searchInputSty:focus-visible {
  outline: none;
}

.studyNameConResultSelected {
  background-color: #F0F0F3;
  width: 170px;
  height: 43px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3vh;
  padding-left: 45px;
  border: none;
}

.studyNameConResult {
  width: 170px;
  height: 43px;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3vh;
  padding-left: 45px;
  cursor: pointer;
  border: none;
  background: transparent;
}

.studyNameStyResult {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 15px;
  color: #1D1C1D;
  margin: 0;
  text-align: left;
  line-height: 100%;
  word-break: break-word;
}

.resultStudyInfoDataCon {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.resultStudyStatsCon {
  display: flex;
  width: 40%;
  max-width: 377px;
  margin-right: 20px;
}

.resultStudyAttributeCon {
  width: 50%;
  max-width: 100%;
  height: 85%;
}

.resultStudyAttributeCon.mediaAttriImg.mediaBrandImg {
  display: flex;
  width: 84% !important;
  height: 93%;
  flex-direction: row;
}

.parentResAttrCon .resultStudyAttributeCon {
  width: 100% !important;
}

.resultStudyAttributeCon.mediaAttriText.mediaBrandText {
  display: flex;
  width: 60%;
  height: 85%;
  flex-direction: row;
}

.resultStudyAttributeCon.mediaAttriText.mediaBrandImg {
  display: flex;
  flex-direction: row;
}

.resultStudyAttributeCon.mediaAttriImg.mediaBrandText {
  display: flex;
  flex-direction: row;
}

.resultUpdateScreenerBtnCon {
  justify-content: flex-end;
}

.parentResAttrCon {
  width: 60%;
  max-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.resultStudyStatsCard {
  background-color: #FFFFFF;
  width: 100%;
  max-width: 377px;
  height: max-content;
  max-height: 100%;
  border-radius: 3px;
  padding-left: 4%;
  padding-right: 4%;
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.1);
  padding-bottom: 5px;
}

.resultStudyStatsline {
  width: 100%;
  height: 1px;
  background-color: #E8E9F3;
  margin-top: 10px;
  margin-bottom: 13px;
}

/* .resultStudyStatsCon .resultStudyStatsline:nth-child(11) {
  margin-bottom: 34px !important;
} */

.resultStudyStatsDataCon {
  display: flex;
  flex-direction: row;
}

.resultStudyStatsHeadingCon {
  display: flex;
  flex: 1;
}

.resultStudyStatsHeading {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  color: #5D5D62;
  margin: 0;
}

.resultStudyStatsValue {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 16px;
  color: #090909;
  margin: 0;
  text-transform: capitalize;
}

a.resultStudyStatsValue {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: initial;
}

.resultStudyAttributeInnerCon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  margin: 0px 20px;
}

.resultStudyAttributeInnerCon:nth-child(2) {
  margin-right: 0;
}

.resultStudyAttributeDataCon {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 6%;
  padding-top: 10px;
  flex-direction: column;
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.1);
  padding-bottom: 0;
}

.resultStudyAttributeStimulusCon {
  display: flex;
  width: 90%;
  height: 40%;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 6%;
  flex-direction: column;
  box-shadow: 1px 1px 1px 1px #E3E2E8;

}

.resultStudyPrimedBrandCon {
  display: flex;
  width: 100%;
  height: max-content;
  background-color: #ffffff;
  flex-direction: column;
  padding: 6%;
  padding-top: 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.1);
  padding-bottom: 0;
}

.resultStudyInfoPercentageCon {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
}

.resultStudyInfoSuccessTxt {
  color: "#1D1C1D";
  font-family: Helvetica;
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
}

.resultStudyInfoPercentageTxtCon {
  display: flex;
  border: 1px solid #5D5D62;
  width: 42px;
  height: 40px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  border-radius: 4px;
}

.resultStudyInfoPercentageTxt {
  color: "#5D5D62";
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
}

.StimulusTxtStyle {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 14px;
  color: #1D1C1D;
  margin: 0;
  margin-bottom: 7px;
}

.resultStimulusPictureCon {
  display: flex;
  width: 100%;
  height: 100%;
  /* border: 2px dashed #A3AAC2; */
  border: 3px dotted #A3AAC2;
  border-radius: 14px;
  margin-top: 4%;
  justify-content: center;
  align-items: center;
}

.pictureIconStyleResult {
  width: 75%;
  height: 75%;
  object-fit: contain;
}

.resultPrimedBrandCon {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.resultPrimedBrandPictureCon {
  display: flex;
  width: 47%;
  max-width: 83px;
  height: 88px;
  border: 3px dotted #A3AAC2;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  margin-left: 0px !important;
  margin-bottom: 20px;
}


.resultPrimedBrandPictureMainUpperCon {
  display: flex;
  flex-direction: column;
  width: 47%;
  max-width: 83px;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
}

.resultPrimedBrandPictureCon12 {
  display: flex;
  width: 100%;
  max-width: 83px;
  height: 88px;
  border: 3px dotted #A3AAC2;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  margin-left: 0px !important;
  margin-top: 1px;
}



.resultStudyAttributeCon.mediaAttriText.mediaBrandText .attributeValueConSty {
  display: flex;
  border: 1px solid #A3AAC2;
  width: 100%;
  max-width: 106px;
  height: 40px;
  align-items: center;
  border-radius: 3px;
  margin-top: 0px;
  margin-bottom: 11px;
  margin-left: 0px !important;
}

.attributeValueTxtSty {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 14px;
  color: #64697B;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 6px;
}

.RawResultsStudyBtnSty {
  background-color: #283182 !important;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  width: 168px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  margin-right: 10px;
}


button.createNewStudyBtnSty.btn.btn-primary {
  background-color: #283182 !important;
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  width: 202px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.createNewStudyBtnSty {
  background-color: #F6F6FC !important;
  border: none;
  color: #090909;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
  width: 202px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
}

.createNewStudyBtnSty:hover {
  background-color: white !important;
  font-weight: 700;
}

button.createNewStudyBtnSty.btn.btn-primary:hover {
  background-color: rgb(66, 115, 240) !important;
}

/* screener Data */

.resultScreenerCompletionStatus {
  background-color: #FFFFFF;
  width: 100%;
  max-width: 408px;
  height: 330px;
  border-radius: 3px;
  padding-left: 4%;
  padding-right: 4%;
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.1);
}

.resultScreenerDataLineGraphCon {
  background-color: #FFFFFF;
  width: 100%;
  height: 330px;
  border-radius: 3px;
  padding-left: 4%;
  padding-right: 4%;
  padding-bottom: 4%;
  /* padding-top: 4%; */
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.1);
}

.resultUpdateScreenerBtnCon {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

.resultstudyDataMainCon .resultUpdateScreenerBtnCon {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 18px;
  position: absolute;
  right: 29px;
  bottom: 29px;
}

.UpdateScreenerBtnStyResult {
  background-color: #F6F6FC;
  /* border: none; */
  border: 1px solid #DADADA;
  color: #1D1C1D;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;

  width: 202px;
  height: 40px;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  margin-left: 7%;
}

.ScreenerCompletionTxtStyResult {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 14px;
  color: #1D1C1D;
  padding-top: 16px;
}

.noGraphTextCon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkedSharedTxtStyResult {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 10px;
  color: #1D1C1D;
  margin-top: 4%;
  margin-bottom: 4%;
  text-align: center;
}

.StaticTextSty {
  font-family: Helvetica;
  font-weight: 700;
  font-size: 10px;
  color: #1D1C1D;
  margin-top: 2%;
  margin-bottom: 1%;
}

.resultScreenerCompletionGraphStatsCon {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  height: 84%;
}

.resultScreenerCompletionGraphStatsCon svg {}

.resultScreenerCompletionStatsCon {
  display: flex;
  flex: .4;
  background-color: #A3AAC2;
}

.resultScreenerCompletionGraphCon {
  display: flex;
  flex: .6;
  background-color: cadetblue;
}

/* study data screen */

.resultstudyDataMainCon {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.resultstudyDataLeftFilterCon {
  display: flex;
  width: 18%;
  height: 85%;
  flex-direction: column;
  justify-content: center;
}

.resultstudyDataRightCon {
  display: flex;
  width: 100%;
  max-width: 377px;
  margin-left: 0;
}

.resultstudyDataRightCon1 {
  display: flex;
  width: 100%;
  max-width: 377px;
  margin-left: 10px;
}


.studyDataScreenFilterBtn {
  background-color: #FFFFFF80;
  border: 1px solid black;
  width: 100%;
  max-width: 164px;
  height: 40px;
  border-radius: 5px;
  font-family: Helvetica;
  font-weight: 400;
  /* font-weight: 700; */
  font-size: 12px;
}

.studyDataScreenFilterBtnActive {
  background-color: #FFFFFF;
  border: 1px solid black;
  width: 100%;
  max-width: 164px;
  height: 40px;
  border-radius: 5px;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
}

.resultstudyDataMainCon .resultstudyDataLeftFilterCon {
  display: none;
}

/* study data screen end */

/* implicit Results */

.resultImplicitResultsMainCon {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.resultImplicitResultsSummaryCon {
  display: flex;
  margin-bottom: 22px;
}

.resultImplicitResultsHistogramCon {
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.10);
}

.resultImplicitResultsHistogramCon>div {
  width: 100% !important;
  max-width: 100%;
  height: 191px !important;
}

.resultImplicitResultsHistogramCon>div>div {
  width: 100% !important;
  max-width: 100%;
  height: 191px !important;
}

.resultImplicitResultsSummaryInnerCon {
  background-color: #FFFFFF;
  padding: 2%;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 3px rgba(29, 28, 29, 0.10);
  width: 100%;
  max-width: 100%;
}

.resultImplicitResultsHistogramCon svg>g:nth-child(5) {
  width: 100px;
}

.table {
  border: 1px solid #A3AAC2;
  border-collapse: collapse;
  margin-bottom: 0px;
}

th {
  border: 1px solid #A3AAC2;
  /* padding: 10px; */
  text-align: center;
  background-color: lightblue;
  color: #090909;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 12px;
}

th.header {
  border: none;
  border-bottom: 1px solid #A3AAC2;
  background-color: #FFFFFF01;
  width: 120px;
  height: 36px;
}

td {
  border: 1px solid #A3AAC2;
  /* padding: 10px; */
  text-align: center;
  color: #1D1C1D;
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  width: 120px;
  height: 36px;
}

.tableHeadingSty {
  background-color: #F0F0F3;
  border: 1px solid #8E8E95;
  width: 120px;
  height: 36px;
}

.summaryTxtStyResult {
  color: #1D1C1D;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
  margin-bottom: 8px;
}

/* filter tab */

.ResultFilterTabMainCon {
  display: flex;
  flex-direction: row;
  /* background-color: #283182; */
  width: 100%;
  height: 97%;
}

.ResultFilterLeftTabCon {
  display: flex;
  flex: .09;
  background-color: #fff;
  flex-direction: column;
}

.ResultFilterRightMainCon {
  display: flex;
  flex: .91;
  background-color: #fff;

  flex-direction: column;
}

.ResultFilterRightUpperCon {
  display: flex;
  flex: .45;
  /* padding-top: 8px; */
  padding-left: 10px;
  /* background-color: #242533; */
}

.ResultFilterRightLowerCon {
  display: flex;
  flex: .55;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ResultFilterRightLowerCon .table {
  width: 100%;
  max-width: 780px;
  justify-content: flex-start;
}

.ResultFilterRightLowerCon .table th {
  height: 32px;
}

.leftFilterImageTextCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 21px;
}

.leftFilterImageCon {
  background-color: #F9F9FC;
  padding: 20px;
  border-radius: 50px;
  align-self: center;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ScreenerFilterIcon {
  height: 22px;
  width: 22px;
}

.leftFilterTextSty {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 11px;
  color: #242533;
  margin: 0;
}

.ParticipantTableHeadingSty {
  background-color: #F9F9FC;
  width: 120px;
  height: 42px;
  justify-content: center;
}

.ResultFilterParticipantStaticsTxt {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 12px;
  color: #ABACBE;
  margin: 7px 0px;
}

.ParticipantStaticsBoldTxt {
  color: #484964;
}

.filterIconSty {
  width: 20px;
  height: 20px;
  margin-top: 10px;

}

.DropDownButtonSty {
  background-color: #F3F3F8;
  border-radius: 8px;
  align-self: center;
  margin-left: 10px;
}

.inputFieldFilterSty {
  background-color: #F3F3F8;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
  align-self: center;
  min-height: 32px;
}

.ResultFilterDropDownMainCon {
  display: flex;
  flex-direction: column;
}

.ResultFilterDropDownRowCon {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.pf_st_first {
  height: 100%;
  position: absolute;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}


/* result page css end */

/* practice study  */

.study2MainContainer {
  display: flex;
  flex-direction: column;
  width: "100%";
  height: 82vh;
}

.topBarPhase1Con {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.study2BoxConPhase1 {
  display: flex;
  flex-direction: row;
  height: 547px;
  margin-top: 63px;
}

.topBarPhase1 {
  background-color: #EAEAEA;
  width: 100%;
  max-width: 831px;
  height: 9px;
  margin-left: 10px;
  border-radius: 4px;
}

.topBarActivePhase1 {
  background-color: #283ED4;
  width: 0%;
  height: 9px;
  border-radius: 4px;
}

.study2RightConPhase1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #EAEAEA;
  margin-left: 2.5%;
  padding-top: 3%;
  padding-bottom: 3%;
  padding-left: 12%;
  padding-right: 12%;
  border-radius: 3px;
  position: relative;
}

.study2leftSideImg {
  max-width: 100%;
  height: 194px;
}

.study2MidImgTickCon .study2leftSideImg {
  position: absolute;
  bottom: 35px;
}

img.study2leftSideImg:nth-child(2) {
  position: absolute;
  max-width: 100%;
  bottom: 35px;
}

.study2RightSideImg {
  max-width: 100%;
  height: 190px;
}

.study2upperImgCon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.study2MidImgCon {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-end;
}

.study2SandImgCon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.study2tickIconImg {
  width: 98px;
  height: 98px;
  margin-bottom: 15%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
}

.study2MidImgTickCon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.study2MidImgTickCon p {
  font-size: 50px;
}

/* New Keyboard page css */



.pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 {
  width: 100%;
  max-width: 360px;
  margin-right: 13px;
  background: #EAEAEA;
}

.pf_st_practise_tab_prime_main .BoxConPhase1 .RightConPhase1 {
  width: 100%;
  max-width: 885px;
  background: #EAEAEA;
  height: 547px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
}

img.keyboardImgsty {
  width: 96%;
}

.pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 {
  width: 100%;
  max-width: 360px;
}

.pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 .leftTextUpper {
  font-size: 24px;
  line-height: 31.2px;
  color: #090909;
  font-weight: 400;
  font-family: Helvetica;
  width: 259px;
  margin: auto;
  margin-top: 37px;
  height: 100%;
}

span.leftTextBold {
  font-weight: 700;
}

@media (min-width: 576px) {
  .participant_fac_main_content .BoxConPhase1 {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .participant_fac_main_content .BoxConPhase1 {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .participant_fac_main_content .BoxConPhase1 {
    max-width: 960px;
  }
}

@media(min-width: 1200px) {
  .participant_fac_main_content .BoxConPhase1 {
    max-width: 1140px;
  }
}

@media(min-width: 1440px) {
  .participant_fac_main_content .BoxConPhase1 {
    max-width: 1320px;
  }
}

@media(max-width: 1370px) {
  .study2MidImgTickCon p {
    font-size: 35px;
  }

  .study2leftSideImg {
    max-width: 100%;
    height: 155px;
  }

  .study2RightSideImg {
    max-width: 100%;
    height: 155px;
  }

  .study2tickIconImg {
    width: 75px;
    height: 75px;
  }

  .study2BoxConPhase1 {
    height: 450px;
  }
}

@media(max-width: 992px) {
  .pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 {
    max-width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .RightConPhase1 {
    max-width: 100%;
    margin: 0;
    height: 310px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 .leftTextUpper {
    width: 100%;
    margin: 0;
    padding: 20px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 .leftTextUpper {
    font-size: 18px;
    line-height: 22px;
  }

  .participant_fac_main_content .BoxConPhase1 {
    width: 95%;
    align-items: center;
  }

  .study2leftSideImg {
    max-width: 100%;
    height: 150px;
  }

  .study2RightSideImg {
    max-width: 100%;
    height: 150px;
  }

  .study2tickIconImg {
    width: 70px;
    height: 70px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .study2MidImgTickCon .study2leftSideImg {
    bottom: 15px;
  }

  img.study2leftSideImg:nth-child(2) {
    bottom: 15px;
  }
}

@media(max-width: 768px) {
  .study2leftSideImg {
    width: 100px;
    height: 120px;
  }

  .study2RightSideImg {
    width: 100px;
    height: 120px;
  }

  .study2BoxConPhase1 {
    height: 450px;
  }
}

@media(max-width: 575px) {
  .pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 .leftTextUpper {
    margin: 0px 5px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .RightConPhase1 {
    margin: 0px 5px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 .leftTextUpper {
    font-size: 15px;
    line-height: 18px;
  }

  .study2leftSideImg {
    width: 80px;
    height: 100px;
  }

  .study2RightSideImg {
    width: 80px;
    height: 100px;
  }

  .study2BoxConPhase1 {
    height: 380px;
  }
}

@media(max-width: 360px) {
  .study2leftSideImg {
    width: 50px;
    height: 70px;
  }

  .study2RightSideImg {
    width: 50px;
    height: 70px;
  }

  .study2tickIconImg {
    width: 50px;
    height: 50px;
  }

  .study2BoxConPhase1 {
    height: 290px;
  }
}

/* End New Keyboard page css */
.resultScreenerCompletionGraphStatsCon>div {
  width: 100% !important;
  height: 100% !important;
}

div#reactgooglegraph-1>div {
  width: 100% !important;
  height: 100% !important;
}

div#reactgooglegraph-1>div {
  width: 100% !important;
  height: 100% !important;
}

div#reactgooglegraph-1 svg {
  width: 100% !important;
  height: 100% !important;
}

.resultScreenerCompletionGraphStatsCon>div>div {
  width: 100% !important;
  height: 100% !important;
}

.resultScreenerCompletionGraphStatsCon g path {
  position: absolute;
  left: 0;
  transform: translate(-12px, 0px);
}

.resultScreenerCompletionGraphStatsCon g path+text {
  position: absolute;
  transform: translate(-12px, 1px);
}

g.google-visualization-tooltip path {
  position: absolute;
  transform: translate(0px, 0px);
}

button#options {
  font-size: 12px;
}

ellipse {
  position: absolute;
  left: 0;
  transform: translate(-12px, 0px);
}

ellipse+text {
  position: absolute;
  transform: translate(-10px, 1px);
}

g[column-id="NotStarted"] {
  position: absolute;
  transform: translate(0px, -4px);
}

g[column-id="Completed"] {
  position: absolute;
  transform: translate(0px, 2px);
}

g[column-id="Incomplete"] {
  position: absolute;
  transform: translate(0px, 4px);
}

button.ResultSeemorebtnSty {
  border: none;
  background: #283182;
  color: white;
  margin-top: 10px;
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin: 0 auto;
  font-size: 14px;
  margin-top: 3vh;
}

button.ResultSeemorebtnSty p {
  margin: 0;
}

.resultStudyAttributeDataCon .row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
}

.resultStudyAttributeDataCon div.col-xs-6 {
  width: 47%;
  display: flex;
  justify-content: center;
  padding: 0;
}

.resultStudyAttributeCon.mediaAttriText.mediaBrandImg .attributeValueConSty {
  /* padding: 5px;
  width: 100%; */
  width: 100%;
  display: flex;
  border: 1px solid #A3AAC2;
  width: 100%;
  max-width: 106px;
  height: 40px;
  align-items: center;
  border-radius: 3px;
  margin-top: 0px;
  margin-bottom: 11px;
  margin-left: 0px !important;
}

.resultStudyAttributeCon.mediaAttriImg.mediaBrandText .attributeValueConSty {
  padding: 5px;
}

.resultStudyAttributeDataCon div.col-xs-6 .resultPrimedBrandPictureCon {
  width: 100%;
  max-width: 83px;
  height: 88px;
  padding: 0;
  margin: 0 !important;
  margin-bottom: 20px !important;
}

.searchBarCon+div::-webkit-scrollbar {
  width: 8px;
}

.searchBarCon+div::-webkit-scrollbar-thumb {
  background: #fff;
  border: 1px solid grey;
  border-radius: 10px;
}

.searchBarCon+div::-webkit-scrollbar-track {
  background: #d3d3d3;
  border-radius: 10px;
  margin-top: 20px;
}

.resultScreenerCompletionGraphStatsCon text {
  font-family: Helvetica !important;
}

.UpdateScreenerBtnStyResult:hover {
  background: #FFF;
  font-weight: 700;
}

.resultImplicitResultsHistogramCon text {
  font-family: Helvetica !important;
}

.studyDataScreenFilterBtn:nth-child(2) {
  margin-top: 2px;
}

p.target_txt_pairing {
  color: #090909;
  font-size: 50px;
}

.cp_dlt {
  position: absolute;
  right: 7%;
  bottom: 2%;
}

@media(max-width: 1680px) {
  .pf_st_prtc_tb {
    padding-top: 20px;
  }
}

@media(max-width: 1440px) {
  .pf_st_prtc_tb {
    padding-top: 10px;
  }

  .study_survey .innerCon {
    width: 75%;
  }
}

@media(max-width: 1400px) {
  .resultStudyAttributeCon.mediaAttriText.mediaBrandText .attributeValueConSty {
    max-width: 86px;
  }

  p.target_txt_pairing {
    font-size: 35px;
  }
}

@media(max-width: 1370px) {
  .pf_st_prtc_tb .leftTextUpper {
    font-size: 21px;
  }

  .pf_st_prtc_tb .leftTextUpper span {
    font-size: 21px;
  }

  .participant_fac_main_content .pf_st_prtc_tb .RightConPhase1 {
    height: 450px;
  }

  .pf_st_prtc_tb .leftSideImg {
    width: 130px;
    height: 155px;
  }

  .pf_st_prtc_tb .RightSideImg {
    max-width: 100%;
    height: 155px;
  }

  .pf_st_prtc_tb .StxtBoxStyleLight {
    width: 60px;
    height: 60px;
  }

  .pf_st_prtc_tb .tickIconImg {
    width: 75px;
    height: 75px;
  }

  .pf_st_prtc_tb .tickIconImgWaiting {
    width: 75px;
    height: 75px;
  }

  .pf_st_prtc_tb .StxtBoxStyle {
    width: 60px;
    height: 60px;
  }

  .pf_st_prtc_tb .leftTextUpper3rd {
    font-size: 21px;
    line-height: 26px;
  }

  .pf_st_prtc_tb .leftTextBold3rd {
    font-size: 21px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .leftConPhase1 .leftTextUpper {
    font-size: 21px;
    line-height: 26px;
  }

  span.leftTextBold {
    font-size: 21px;
  }

  .pf_st_practise_tab_prime_main .BoxConPhase1 .RightConPhase1 {
    height: 450px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 {
    height: 450px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.lft_txt_pairing {
    font-size: 30px;
  }

  .pf_st_rel_tab_main .BoxConPhase1 .RightConPhase1 p.right_txt_pairing {
    font-size: 30px;
  }

  .study_rel.pf_st_rel_tab_main p.target_txt_pairing {
    font-size: 30px;
  }
}

@media(max-width: 1200px) {
  .resultStudyStatsCard {
    max-width: 377px;
  }

  .resultStudyStatsValue {
    font-size: 14px;
  }

  .resultStudyAttributeCon {
    flex-direction: column;
  }

  .resultStudyAttributeDataCon {
    margin-bottom: 20px;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandText .resultStudyAttributeDataCon .row {
    flex-direction: column;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandText .resultStudyAttributeDataCon div.col-xs-6 {
    width: 100%;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandText .attributeValueConSty {
    max-width: 100%;
  }

  button.createNewStudyBtnSty.btn.btn-primary {
    width: 100%;
    margin-left: 15px;
  }

  p.target_txt_pairing {
    font-size: 28px;
  }

  .study_survey .innerCon {
    width: 80%;
  }
}

@media(max-width: 992px) {
  .RightConResultMain {
    width: 77%;
  }

  .resultGenerateLinksBtnSty {
    width: 190px;
    font-size: 10px;
    height: 40px;
  }

  .resultReateNewStudyMainCon {
    flex-direction: column;
  }

  .createNewStudyBtnSty {
    width: 190px;
    font-size: 10px;
    height: 40px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .resultStudyInfoDataCon {
    flex-direction: column;
  }

  .resultStudyStatsCard {
    max-width: 100%;
    padding-bottom: 20px;
  }

  .resultStudyStatsCon {
    width: 100%;
    max-width: 100%;
  }

  .resultStudyAttributeCon {
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandText {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  .resultStudyPrimedBrandCon {
    margin-top: 0px;
    max-width: 100%;
  }

  .resultStudyAttributeDataCon div.col-xs-6 {
    width: 33%;
    margin: 0px 0px;
  }

  .resultPrimedBrandPictureCon {
    width: 47%;
    max-width: 100%;
    height: 100px;
  }

  .resultStudyAttributeDataCon div.col-xs-6 .resultPrimedBrandPictureCon {
    max-width: 100px;
    height: 75px;
  }

  .resultStudyAttributeDataCon {
    max-width: 100%;
  }

  .resultScreenerCompletionStatus {
    max-width: 100%;
  }

  .ResultTabText {
    font-size: 10px;
  }

  .ResultFilterTabMainCon {
    flex-direction: column;
  }

  .ResultFilterLeftTabCon {
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 20px;
  }

  .resultStudyAttributeInnerCon:nth-child(2) {
    margin-left: 15px;
  }

  .resultStudyAttributeInnerCon {
    margin-top: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandText {
    width: 100%;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
  }

  .resultStudyAttributeInnerCon {
    width: 100%;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandText .resultStudyAttributeInnerCon:nth-child(2) {
    margin-left: 0px;
    margin-top: 0px;
  }

  .resultstudyDataLeftFilterCon {
    width: 30%;
  }

  .parentResAttrCon {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .ResultTabCon {
    height: 48px;
  }

  .resultReateNewStudyMainCon {
    padding-left: 25%;
    padding-right: 18.5%;
  }

  button.createNewStudyBtnSty.btn.btn-primary {
    margin-left: 0;
  }

  p.target_txt_pairing {
    font-size: 22px;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg {
    width: 100% !important;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg {
    flex-direction: column;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg .resultStudyAttributeInnerCon:nth-child(2) {
    margin-left: 0;
    margin-top: 0;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg {
    flex-direction: column;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg .resultStudyAttributeInnerCon:nth-child(2) {
    margin-left: 0;
    margin-top: 0;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandText {
    flex-direction: column;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandText .resultStudyAttributeInnerCon:nth-child(2) {
    margin-left: 0;
    margin-top: 0;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg .row {
    flex-direction: column;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg .attributeValueConSty {
    max-width: 100%;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg .resultStudyAttributeDataCon div.col-xs-6 {
    width: 100%;
  }

  .study_survey .innerCon {
    width: 100%;
  }
}

@media(max-width: 768px) {
  .ResultFilterDropDownRowCon {
    flex-direction: column;
  }

  .DropDownButtonSty {
    align-self: flex-start;
    width: 100%;
    margin-bottom: 5px;
  }

  .inputFieldFilterSty {
    width: 100%;
    margin-left: 20px;
  }

  .ResultFilterRightLowerCon {
    overflow-x: scroll;
    overflow-y: scroll;
  }

  .resultImplicitResultsSummaryInnerCon {
    overflow: scroll;
  }

  .leftConResultMain {
    max-width: 130px;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg {
    flex-direction: column;
    height: 100%;
    max-height: 100%;
  }

  .resultStudyAttributeInnerCon {
    width: 100%;
  }

  .resultStudyAttributeInnerCon:nth-child(2) {
    margin-left: 0px;
    margin-top: 0;
  }

  .resultStudyAttributeDataCon div.col-xs-6 {
    width: 33%;
  }

  .resultstudyDataMainCon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .resultstudyDataLeftFilterCon {
    flex-direction: row;
  }

  .resultstudyDataRightCon {
    margin-left: 0;
  }

  .resultstudyDataLeftFilterCon {
    width: 100%;
  }

  button.createNewStudyBtnSty.btn.btn-primary {
    margin-left: 0px;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg {
    width: 100% !important;
  }

  .resultReateNewStudyMainCon {
    padding-left: 21%;
    padding-right: 25.5%;
  }

  .searchBarCon {
    width: 100%;
  }

  .searchInputSty {
    width: 90px;
  }

  .studyNameConResultSelected {
    padding-left: 20px;
    padding-right: 50px;
  }

  .studyNameConResult {
    padding-left: 20px;
    padding-right: 50px;
  }

  .studyDataScreenFilterBtn:nth-child(2) {
    margin-top: 0px;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg {
    margin-top: 0;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg {
    flex-direction: column;
  }

  .pf_gropID_pg .vstack {
    flex-direction: column;
  }

  .participant_fac_main_content.container input.num_of_participant_input {
    max-width: 100%;
  }
}

@media(max-width: 730px) {
  .resultReateNewStudyMainCon {
    padding-left: 22%;
    padding-right: 25.5%;
  }
}

@media(max-width: 690px) {
  .resultReateNewStudyMainCon {
    padding-left: 22.5%;
    padding-right: 15.5%;
  }
}

@media(max-width: 640px) {
  .resultReateNewStudyMainCon {
    padding-left: 23%;
    padding-right: 15.5%;
  }
}

@media(max-width: 600px) {
  .BoxConResultMain {
    flex-direction: column;
  }

  .leftConResultMain {
    max-width: 100%;
  }

  button.studyNameConResultSelected {
    max-width: 98%;
    width: 100%;
    border-radius: 25px;
  }

  .searchBarCon+div {
    height: 24vh !important;
  }

  .RightConResultMain {
    width: 100%;
  }

  .resultReateNewStudyMainCon {
    padding: 0px 5.5px;
  }

  .ResultTabCon {
    width: 96%;
  }

  .resultstudyDataLeftFilterCon {
    margin-bottom: 15px;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandImg+.resultUpdateScreenerBtnCon {
    position: relative;
    right: 0;
    bottom: 0;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandText+.resultUpdateScreenerBtnCon {
    position: relative;
    right: 0;
    bottom: 0;
  }

  .resultStudyAttributeCon.mediaAttriText.mediaBrandImg+.resultUpdateScreenerBtnCon {
    position: relative;
    right: 0;
    bottom: 0;
  }

  .resultStudyAttributeCon.mediaAttriImg.mediaBrandText+.resultUpdateScreenerBtnCon {
    position: relative;
    right: 0;
    bottom: 0;
  }

  .studyNameConResult {
    width: 100%;
    padding-left: 4px;
    padding-right: 0;
  }

  .searchInputSty {
    width: 96%;
  }

  button.ResultSeemorebtnSty {
    margin-bottom: 10px;
  }

  .study_main_content_cstm.false.container .num_of_participant_Con .generate_Link_Btn {
    margin-left: 0px;
  }
}

@media(max-width: 550px) {
  .pf_gropID_pg {
    max-width: 100%;
  }
}

@media(max-width: 470px) {
  .resultStudyAttributeDataCon div.col-xs-6 {
    width: 47%;
  }

  .resultStudyAttributeDataCon div.col-xs-6 .resultPrimedBrandPictureCon {
    max-width: 150px;
    height: 120px;
  }

  .resultPrimedBrandPictureCon {
    width: 47%;
    max-width: 150px;
    height: 120px;
  }

  .study_main_content_cstm.false.container .num_of_participant_Con .generate_Link_Btn {
    font-size: 11px;
  }

  .num_of_participant_Con .vstack>div button {
    margin-left: 10px;
  }

  .study_survey .stydyServey {
    line-height: 40px;
  }
}

@media(max-width: 375px) {
  p.target_txt_pairing {
    font-size: 20px;
  }
}

.study_main_content_cstm.false.container .num_of_participant_Con input.num_of_participant_input::-webkit-inner-spin-button {
  height: 40px;
  opacity: 1;
  visibility: visible;
}

.participant_fac_main_content.container input.num_of_participant_input::-webkit-inner-spin-button {
  height: 40px;
  opacity: 1;
  visibility: visible;
}

.num_of_participant_inputDashboard::-webkit-inner-spin-button {
  height: 40px;
  opacity: 1;
  visibility: visible;
}

.pf_cstm_screener_main.pf_survey_ques {
  height: 80vh;
}

.pf_gropID_pg p {
  text-align: center;
  width: 100%;
  max-width: 600px;
  font-size: 18px;
  margin: auto;
  margin-bottom: 20px;
}

.resultReateGenerateLinkeBtnCon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 35px;
}

.resultReateGenerateLinkeBtnCon1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.num_of_participant_inputDashboard {
  width: 100%;
  max-width: 100px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid black;
  margin-right: 12px;
  text-align: center;
}

.resultGenerateLinksBtnSty.btn.btn-primary {
  margin: 0 !important;
}

.resultReateNewStudyMainCon {
  padding: 0 12px;
  width: 87%;
  margin: auto;
}

.main_cstm.pg_studies.container .resultReateNewStudyMainCon {
  width: 87%;
  margin-right: unset;
}

@media(max-width: 1400px) {
  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    width: 85%;
  }

  .main_cstm.pg_studies.container .resultReateGenerateLinkeBtnCon {
    margin-right: 10px;
  }
}

@media(max-width: 1200px) {
  .num_of_participant_inputDashboard {
    max-width: 70px;
    margin-right: 10px;
  }

  button.createNewStudyBtnSty.btn.btn-primary {
    width: 25%;
  }

  .resultReateGenerateLinkeBtnCon {
    margin-right: 20px;
  }

  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    flex-wrap: wrap;
    width: 81%;
    padding: 0px;
  }

  .main_cstm.pg_studies.container button.createNewStudyBtnSty.btn.btn-primary {
    margin-top: 10px;
    margin-left: 0px;
  }
}

@media(max-width: 992px) {
  .resultReateNewStudyMainCon {
    width: 79%;
  }

  button.createNewStudyBtnSty.btn.btn-primary {
    width: 40%;
  }

  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    width: 79%;
  }
}

@media(max-width: 991px) {
  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    width: 75%;
  }
}

@media(max-width: 768px) {
  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    width: 78%;
    padding: 0px 20px 0px 0px;
  }
}

@media(max-width: 767px) {
  .resultReateGenerateBtnCon {
    flex-direction: column;
  }

  .num_of_participant_inputDashboard {
    max-width: 30%;
    height: 40px;
  }

  .resultGenerateLinksBtnSty {
    width: 70%;
  }

  .resultReateGenerateLinkeBtnCon {
    margin-right: 0px;
    margin-bottom: 10px;
  }

  button.createNewStudyBtnSty.btn.btn-primary {
    width: 100%;
  }

  .resultReateGenerateLinkeBtnCon1 {
    margin-left: 0px;
  }

  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    width: 79%;
    padding: 0px 25px 0px 0px;
  }

  .main_cstm.pg_studies.container .resultReateGenerateLinkeBtnCon {
    margin-right: 0;
  }
}

@media(max-width: 600px) {
  .main_cstm.pg_studies.container .resultReateNewStudyMainCon {
    width: 100%;
    padding: 0 12px;
  }
}

/* asdasdsadsadd */
.pf_study_inner_cstm.pf_cstm_screener_main .BoxCon.pf_screener_ques {
  margin-top: 0px;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.pf_st_prtc_tb {
  padding-top: 0px;
}

.pf_st_first {
  position: relative;
  transform: unset;
  top: 0;
  left: 0;
}

.study2MainContainer {
  width: 100%;
  max-width: 1240px;
  margin: auto;
}

.study2BoxConPhase1 {
  width: 100%;
  max-width: 1240px;
  margin: 75px auto;
}

.pf_st_practise_tab_target_main .study2BoxConPhase1 {
  margin: auto;
}

.study2RightConPhase1 {
  margin-left: 0px;
  margin: 0px 20px;
}

.result_lft_usrs {
  max-width: 100%;
  display: block;
  max-height: 350px;
  overflow-y: auto;
}

.result_lft_usrs .resultstudyDataRightCon,
.result_lft_usrs .resultScreenerCompletionStatus,
.result_lft_usrs .resultScreenerCompletionStatus {
  display: block;
  max-width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}



@media(max-width: 1400px) {
  .RawResultsStudyBtnSty {
    width: 150px;
  }

  .createNewStudyBtnSty {
    width: 150px;
  }
}

@media(max-width: 1200px) {
  .createNewStudyBtnSty {
    margin-top: 10px;
  }
}